import { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import { handleLogoutFromServer } from "../../apis/logout";
import { useNavigate } from 'react-router-dom'
import './header.css'
import IdleTimer from "../../helper/idleTimer";
import { IDLE_TIME_IN_SECONDS } from "../../config";
import useClickOutside from "../../hooks/useClickOutside";
let role = sessionStorage.getItem('roles');
function Header() {
    const domNomde = useClickOutside(() => {
        setView(null)
    })
    const [view, setView] = useState(null)
    const navigate = useNavigate()
    const from = "/login";
    const handleLogout = () => {
        handleLogoutFromServer()
            .then((res) => {
                if (res.status === 200) {
                    sessionStorage.clear();
                    navigate(from, { replace: true });
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const Dropdown = ({ children }) => {
        return <div ref={domNomde} className=" bg-white rounded-lg shadow-xl w-40 absolute left-1/2 -translate-x-1/2 translate-y-2">
            <div className="divide-y divide-gray-200">
                {children}
            </div>
        </div>
    }

    return (
        <header className=" bg-[color:var(--color2)] sticky top-0 z-20">
            <div className="container_xxl">
                <div className="row items-center justify-between">
                    <div>
                        <Link to={`/`} className='text-white font-semibold text-lg'>IconMama {/*Admin*/}</Link>
                    </div>
                    <div>
                        <ul className="flex items-center space-x-8 text-white font-semibold py-2">
                            <li className="linkItem">
                                <Link to={`/`}>Dashboard</Link>
                            </li>
                            <li className="relative">
                                <Link to={`#`} onClick={() => setView('catalog')}>
                                    Catalog
                                </Link>
                                {view === 'catalog' &&
                                    <Dropdown>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/products`}>Products</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/category`}>Category</Link>
                                        </li>
                                    </Dropdown>}
                            </li>
                            <li className="relative">
                                <Link to={`#`} onClick={() => setView('sales')}>
                                    Sales
                                </Link>
                                {view === 'sales' &&
                                    <Dropdown>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/orders`}>Orders</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/taxes`}>Taxes</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/bulk-orders`}>Bulk Orders</Link>
                                        </li>
                                    </Dropdown>}
                            </li>
                            <li className="relative">
                                <Link to={`#`} onClick={() => setView('customers')}>
                                    Customers
                                </Link>
                                {view === 'customers' &&
                                    <Dropdown>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/customers`}>All Customers</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/coupons`}>Coupons</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/special-customers`}>Special Customers</Link>
                                        </li>
                                    </Dropdown>}
                            </li>
                            <li className="relative">
                                <Link to={`#`} onClick={() => setView('filters')}>
                                    Filters
                                </Link>
                                {view === 'filters' &&
                                    <Dropdown>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/brands`}>Brands</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/frames`}>Frames</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/lens`}>Lens</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/size`}>Size</Link>
                                        </li>
                                        <li className="linkItem text-black px-3">
                                            <Link className="block !py-2 " to={`/lens-size-mapping`}>Lens-Size Mapping</Link>
                                        </li>
                                    </Dropdown>}
                            </li>
                            <li className="linkItem">
                                <Link to={`/reports`}>Reports</Link>
                            </li>
                            {role === 'idigitize' &&
                                <li className="linkItem">
                                    <Link to={`/blogs`}>Blogs</Link>
                                </li>}
                            <li>
                                <button onClick={handleLogout} className="px-4 py-2 rounded text-white bg-[color:var(--color3)]">Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header