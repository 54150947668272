import React, { useState } from 'react'


const SummaryPrice = ({ products, setProducts, name }) => {
  const [type, setType] = useState('single')
  const [price, setPrice] = useState(0)
  const handleChange = (e) => {
    setType(e.target.value)
  }

  const handleAllInputChange = (e) => {
    const val = e.target.value
    setPrice(val)
    const temp = [...products]
    temp.map((it) => {
      return it[name] = val
    })
    setProducts(temp)
  }

  return (
    <>
      <div className=' space-y-4' >
        <div className='flex'>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input onChange={handleChange} type="radio" value={'single'} name={name} checked={type === 'single'} />
            <p>Apply single price to all SKUs</p>
          </label>
          {type === 'single' && <input name={price} value={price} onChange={handleAllInputChange} className='w-16 input text-center ml-6' />}
        </div>
        <div>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input onChange={handleChange} type="radio" value={'unique'} name={name} checked={type === 'unique'} />
            <p>Apply unique prices by attribute to each SKU</p>
          </label>
        </div>
        {/* <div>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input type="radio" value={'skip'} name='price' checked={type === 'skip'} />
            <p>Skip pricing at this time</p>
          </label>
        </div> */}
      </div>
    </>
  )
}

export default SummaryPrice