import { Dialog, Slide, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useState, useRef } from 'react'
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Editor from '../../components/elements/Editor';
import useCreate from '../../hooks/useCreate';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true
function AddBlogs({ open, handleClose }) {
    const initialstate = {
        blogTitle: '',
        blogBannerImage: '',
        blogContent: '',
        blogStatus: true,
    }
    const fileInputRef = useRef(null)
    const [blogFormData, setBlogFormData] = useState(initialstate)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [imagePreview, setImagePreview] = useState('')
    const { setDataToServer, } = useCreate({
        refreshUrl: 'blogsList',
        url: 'blogsCreate',
        onSuccess: () => { 
            setIsSuccess(true)
        },
        onError: () => { 
            setIsError(true)
        }
    })
    const handleBlogFormData = (e) => {
        let $this = e.target
        let data = {
            ...blogFormData,
            blogTitle: $this.value
        }
        setBlogFormData({ ...data })
    }

    const handleSubmitBlogFormData = (e) => {
        e.preventDefault()
        setDataToServer.mutate(blogFormData)
    }

    const handleChangeEditor = (e, value) => {
        let data = {
            ...blogFormData,
            blogContent: value
        }
        setBlogFormData({ ...data })
    }

    const closeErrorMessage = () => {
        setIsSuccess(false)
        setIsError(false)
    }

    const handleOpenFileUploadDialog = () => {
        fileInputRef.current.click()
    }

    const handleChangeImageFile = (e) => {
        let file = e.target.files[0]
        const objectUrl = URL.createObjectURL(file)
        setImagePreview(objectUrl)
        let data = {
            ...blogFormData,
            blogBannerImage: file
        }
        setBlogFormData(data)
    }

    const clearData = () => {
        setBlogFormData(initialstate)
        handleClose()
    }
    return (
        <Dialog fullScreen fullWidth={true} open={open} onClose={clearData} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={clearData}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading'>Add Blog</h1>
                    </div>
                </div>
                <div className='form-body py-8'>
                    <form className='' autoComplete='off' onSubmit={handleSubmitBlogFormData}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Title <small className="text-red-700">*</small></label>
                                <input type="text" required={IS_REQUIRED} placeholder='Enter Blog Title' value={blogFormData.blogTitle} onChange={handleBlogFormData} name='blogTitle' className='input' />
                            </div>
                            <div className=''>
                                <label htmlFor="Blog Title" className='label'>Blog Banner Image <small className="text-red-700">*</small></label>
                                {imagePreview && <img src={imagePreview} alt="blog image" className='object-cover my-4' />}
                                <div>
                                    <Button variant="contained" color='success' className='primaryBtn-contained' onClick={handleOpenFileUploadDialog}>
                                        {imagePreview ? 'Update Banner Image' : 'Add Banner Image'}
                                    </Button>
                                    <input ref={fileInputRef} type="file" className='hidden' onChange={handleChangeImageFile} />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="Blog Title" className='label'>Blog Content <small className="text-red-700">*</small></label>
                                <Editor value={blogFormData?.blogContent} onChange={handleChangeEditor} />
                            </div>
                        </div>
                        {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setDataToServer?.error?.message}, Please try again later</Alert>}
                        {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Blog Created Succesfully</Alert>}
                        <div className='text-center my-6'>
                            {false ? <Spinner /> :
                                <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                    Publish Blog
                                </Button>}
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default AddBlogs