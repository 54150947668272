function Input (props){
    const {label,placeholder,onChange,name,className,required,value,...inputProps} = props
return(
    <>
    <div className="flex">
    <div className="w-1/3">
   <label className="label" htmlFor={label}>{label}</label>
   </div>
   <div className="w-1/2">
   <input type="text" name={name}  {...inputProps} onChange={onChange} placeholder={placeholder} required = {required} value={value} className={className} />
   </div>
    </div>
    </>
)
}
export default Input