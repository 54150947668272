import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(TOKEN_KEY);

export const getCategoryList = async (data) => {
    // let q = key.queryKey[1];
    let fd = new FormData()
    for (var key in data){

        fd.append(key,data[key])
    }
    // let token = cookies.get(TOKEN_KEY);
    // let res = await q({ method: "GET", url: `${API_ENDPOINT}categorylist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    // let res = await axios({ method: "GET", url: `${API_ENDPOINT}categorylist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    let res = await axios({ method: "POST", data:fd, url: `${API_ENDPOINT}categorylist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const handleUpdateCategoryStatus = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    const { id, home } = data
    let fd = new FormData()
    fd.append('id', id)
    fd.append('status', home)
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}categorystatus`, headers: { Authorization: token ? `Bearer ${token}` : '', } },)
    return res
};
export const addCategory = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        if (Array.isArray(data[key])) {
            fd.append(key, JSON.stringify(data[key]));
        } else {
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}categorycreate`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};

export const updateCategory = async (data) => {
    // let token = cookies.get(TOKEN_KEY);

    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);

    }
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}categoryupdate`, headers: { Authorization: token ? `Bearer ${token}` : '', } })

    return res
};

export const editCategory = async (id) => {
    // let token = cookies.get(TOKEN_KEY);

    let fd = new FormData()
 
        fd.append('id',id);
        
 
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}getCategoryDetails`, headers: { Authorization: token ? `Bearer ${token}` : '', } })

    return res
};


export const deleteCategory = async (id) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    fd.append('id', id)
    let res = await axios({ method: "POST", data: fd, url: `${API_ENDPOINT}categorydelete`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};

