import { useState } from 'react'
import useRead from '../../../hooks/useRead'
import AttributeValueGrid from './attributes/AttributeValueGrid'



const AttributeValues = ({ configData, dispatch }) => {
  const [data, setData] = useState([])

  const { queryData } = useRead({
    url: 'getAttributeList',
    initialData: {},
    onSuccess: (data) => {
      setData(data.data)
    }
  })

  return (
    <>
      <div className=' space-y-6'>
        <div className=''>
          <h1 className='text-gray-700 font-semibold text-3xl'>Step 2: Attribute Values</h1>
          <p className='text-gray-700 text-sm py-2'>Select values from each attribute to include in this product. Each unique combination of values creates a unique product SKU.</p>
        </div>
        <div className=' grid grid-cols-2 gap-4'>
          {data.map((value, idx) => {
            const id = value.attribute_id
            return id in configData ? <AttributeValueGrid configData={configData} dispatch={dispatch} value={value} key={idx} /> :<></>
          })}
        </div>
      </div>
    </>
  )
}

export default AttributeValues