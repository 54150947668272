import React, { useState, useReducer, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import SelectAttributes from './SelectAttributes';
import AttributeValues from './AttributeValues';
import BulkImagesAndDetails from './BulkImagesAndDetails';
import Summary from './summary/Summary';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi2'
import { BsInfoCircleFill } from 'react-icons/bs'
import useCreate from '../../../hooks/useCreate';
import useConfigRequest from '../../../hooks/useConfigRequest';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loader from '../../loader';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const reducer = (state, action) => {
    switch (action.type) {
        case 'select_attr': {
            const { payload } = action
            const { attribute_id, attribute_name, attribute_code } = payload
            // Check if key exists in the state
            // if exists keep it as it is
            // else add
            const hasKey = attribute_id in state;
            if (!hasKey) {
                return {
                    ...state,
                    [attribute_id]: {
                        attribute_id: attribute_id,
                        attribute_name,
                        attribute_code,
                        values: {}
                    }
                }
            }
            return state
        }
        case 'remove_attr': {
            const { payload } = action // get attr id
            const data = { ...state }
            delete data[payload]
            return data
        }
        case 'select_attr_val': {
            const { payload } = action
            const { attribute_id, value } = payload
            const data = { ...state }
            data[attribute_id].values[value.attribute_value_id] = { attribute_id: attribute_id, attribute_name: data[attribute_id].attribute_name, ...value }
            return data
        }
        case 'remove_attr_val': {
            const { payload } = action
            const { attribute_id, value } = payload
            const data = { ...state }
            delete data[attribute_id].values[value.attribute_value_id]
            return data
        }
        case 'loadConfig': {
            const { payload } = action
            const tempConfig = {}
            payload.forEach(({ attributes }) => {
                attributes.forEach((attr) => {
                    const { attribute_id, attribute_name, attribute_value_name, attribute_value_id } = attr
                    if (attribute_id in tempConfig) {
                        tempConfig[attribute_id].values[attribute_value_id] = {
                            attribute_value_name: attr['attribute_value_name'],
                            attribute_value_id: attr['attribute_value_id'],
                            attribute_name: attribute_name,
                            attribute_id: attribute_id
                        }
                    } else {
                        tempConfig[attribute_id] = {
                            attribute_name: attr['attribute_name'],
                            attribute_id: attr['attribute_id'],
                            values: {
                                [attribute_value_id]: {
                                    attribute_value_name: attr['attribute_value_name'],
                                    attribute_value_id: attr['attribute_value_id'],
                                    attribute_name: attribute_name,
                                    attribute_id: attribute_id
                                }
                            }
                        }
                    }
                })
            })
            return tempConfig
        }
        default: {
            console.log("Unknown Case")
        }
    }
    console.log(action.type, state)
}

const Configurations = ({ open, handleClose, editData, defaultConfigData }) => {
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [step, selectedStep] = useState(1)
    const handlePushPrevStep = () => {
        selectedStep(step - 1)
    }
    // Configuration Data
    const [configData, dispatch] = useReducer(reducer, {});
    const [products, setProducts] = useState([])
    const [prevConfigData, setPrevConfigData] = useState({})

    const handlePushNextStep = () => {
        // Run the permutation algorithm here
        if (step === 2) {
            console.log("Step 2")

            function cartesianProduct(arrays) {
                if (!arrays.length) {
                    return [[]];
                }

                const firstArray = arrays[0];
                const remainingArrays = arrays.slice(1);

                const remainingProduct = cartesianProduct(remainingArrays);

                const product = [];
                for (const element of firstArray) {
                    for (const subproduct of remainingProduct) {
                        product.push([element, ...subproduct]);
                    }
                }

                return product;
            }

            const permutations = (data) => {
                const finalData = []
                const attrs = Object.keys(data)
                attrs.forEach((i) => {
                    const tempArr = []
                    Object.keys(configData[i].values).forEach((j) => {
                        tempArr.push(configData[i].values[j])
                    })
                    if (tempArr.length > 0) {
                        finalData.push(tempArr)
                    }
                })


                const product = cartesianProduct(finalData)
                const finalProductsArray = []
                product.forEach((i) => {
                    // Check prev images present or not.
                    let key = ''
                    i.forEach((a) => {
                        if (!key) {
                            key = a.attribute_value_name
                        } else {
                            key += '-' + a.attribute_value_name
                        }
                    })
                    // prevConfigData
                    // check if exists else create empty
                    const imgs = prevConfigData[key]?.images.map((it) => { 
                        return it.image_id })
                    const prevImgs = prevConfigData[key]?.images.map((it) => { 
                        return it.image_url })

                    console.log('PrevImgs',prevImgs)
                    const data = {
                        attributes: [...i],
                        images_of_product: imgs || [],
                        images_of_product_preview: prevImgs || [],
                        selling_price: prevConfigData[key]?.selling_price || '',
                        mrp_price: prevConfigData[key]?.mrp_price || '',
                        quantity: prevConfigData[key]?.quantity || '',
                        status: prevConfigData[key]?.status || true,
                        product_map_id: prevConfigData[key]?.product_map_id || "",
                        product_name: prevConfigData[key]?.product_name || "",

                    }
                    finalProductsArray.push(data)
                })
                return finalProductsArray

            }


            const permutedData = permutations(configData)
            setProducts(permutedData)
        }

        if (step !== 3) {
            selectedStep(step + 1)
        }

        if (step === 3) {
    
            // console.log(JSON.stringify(products))
        }
    }

    const handleCreateConfig = () => {
        createConfig.mutate({
            product_id: editData.product_id,
            config: products
        }, {
            onSuccess: (data) => {

            },
            onError: () => {

            }
        })
    }

    const { setDataToServer: createConfig } = useConfigRequest({
        refreshUrl: 'getAttributeList',
        url: "createConfig",
        onSuccess: () => {
            setIsSuccess(true)
            setTimeout(() => {
                closeErrorMessage()
            }, 2000)
        },
        onError: () => {
            setIsError(true)
            setTimeout(() => {
                closeErrorMessage()
            }, 2000)
        }
    })

    const closeErrorMessage = () => {
        setIsError(false)
        setIsSuccess(false)
        handleClose()
        selectedStep(1)
    }

    useEffect(() => {
        const tempData = defaultConfigData
        if (tempData.length > 0) {
            // loadconfig
            dispatch({ type: 'loadConfig', payload: tempData })

            // load prev images: This will load the previously applied iamges to the prevConfigData which would be later used in 4th step
            const tempPrevConfigImg = {}
            tempData.forEach((p) => {
                let key = ''
                p.attributes.forEach((a) => {
                    if (!key) {
                        key = a.attribute_value_name
                    } else {
                        key += '-' + a.attribute_value_name
                    }
                })
                let temp = {
                    images: [],
                    selling_price: '',
                    mrp_price: '',
                    quantity: '',
                    product_map_id: "",
                    status: '',
                    product_name:''
                }

                tempPrevConfigImg[key] = structuredClone(temp)
                tempPrevConfigImg[key].images = p.images_of_product
                tempPrevConfigImg[key].selling_price = p.selling_price
                tempPrevConfigImg[key].mrp_price = p.mrp_price
                tempPrevConfigImg[key].quantity = p.quantity
                tempPrevConfigImg[key].status = p.status
                tempPrevConfigImg[key].product_map_id = p.product_map_id || ""
                tempPrevConfigImg[key].product_name = p.product_name  || ""
            })
            setPrevConfigData(tempPrevConfigImg)

        }
    }, [defaultConfigData])

    return (
        <>
            <Dialog disableEscapeKeyDown={true} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <div className='container_xxl mb-10'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>{defaultConfigData.length > 0 ? 'Edit' : 'Create'} Product Configurations </h1>
                        </div>
                    </div>
                    <div className=' space-y-4 mt-4'>
                        {
                            defaultConfigData.length > 0 ? <div className='bg-[#fffbbb] p-4 flex '>
                                <BsInfoCircleFill className='mr-4 mt-[0.325rem] min-h-[1rem] min-w-[1rem]' />
                                <p>When you remove or add an attribute, we automatically update all configurations and you will need to recreate current configurations manually.</p>
                            </div> : <></>
                        }

                        <div className='flex items-center justify-between py-4 bg-gray-100 px-4 rounded'>
                            <div></div>
                            <div className=' space-x-8 flex items-center'>
                                <button onClick={handleClose} className=' text-blue-400 font-semibold'>Cancel</button>
                                <button disabled={step === 1 && true} onClick={handlePushPrevStep} className="px-6 py-2 rounded border-gray-700 border disabled:cursor-not-allowed disabled:opacity-50">
                                    <div className='flex items-center justify-center space-x-2'>
                                        <HiChevronLeft className=' w-5 h-5 text-black' />
                                        <span>Back</span>
                                    </div>
                                </button>
                                {step === 3
                                    ? <button onClick={handleCreateConfig} className="px-6 py-2 rounded text-white bg-[color:var(--color3)] border border-[color:var(--color3)]">
                                        {
                                            createConfig.isLoading ? (
                                                <div className='flex items-center justify-center space-x-2'>
                                                    <span>Generating...</span>
                                                    <Loader />
                                                </div>
                                            ) : (
                                                <div className='flex items-center justify-center space-x-2'>
                                                    <span>Generate Variations</span>
                                                    <HiChevronRight className=' w-5 h-5 text-white' />
                                                </div>
                                            )
                                        }



                                    </button>
                                    : <button onClick={handlePushNextStep} className="px-6 py-2 rounded text-white bg-[color:var(--color3)] border border-[color:var(--color3)]">
                                        <div className='flex items-center justify-center space-x-2'>
                                            <span>Next</span>
                                            <HiChevronRight className=' w-5 h-5 text-white' />
                                        </div>
                                    </button>}
                            </div>
                        </div>
                        <div>
                            {step === 1 && <SelectAttributes configData={configData} dispatch={dispatch} />}
                            {step === 2 && <AttributeValues configData={configData} dispatch={dispatch} />}
                            {step === 3 && <Summary editData={editData} products={products} setProducts={setProducts} configData={configData} dispatch={dispatch} />}
                        </div>
                    </div>
                </div>
                {isSuccess && <Snackbar open={isSuccess} autoHideDuration={6000} onClose={closeErrorMessage}>
                    <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Product Variations Created Succesfully</Alert>
                </Snackbar>
                }
                {isError && <Snackbar open={open} autoHideDuration={6000} onClose={closeErrorMessage}>
                    <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{createConfig?.error?.message}, Please try again later</Alert>
                </Snackbar>
                }
            </Dialog>
        </>
    )
}

export default Configurations