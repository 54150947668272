import React, { useEffect, useRef, useState } from 'react'
import Slide from '@mui/material/Slide';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { getCategoryList } from '../../apis/category';
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { editProduct } from '../../apis/product';
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';

import Modal from '@mui/material/Modal';
import { GrAdd } from 'react-icons/gr'
import { useNavigate, useParams } from 'react-router-dom';
import Configurations from '../../components/section/configurations';
import ProductDetails from '../../components/elements/ProductDetails/ProductDetails';
import useReadUpdated from '../../hooks/updated/useRead';
import useRead from '../../hooks/useRead';
import Loader from '../../components/loader';
import Layout from '../../components/layout';
import GeneratedProducts from './GeneratedProducts';
import { toast } from 'react-hot-toast';
import deBounce from '../../hooks/useDebounce';
import useCreate from '../../hooks/useCreate';
import { ReactSortable } from 'react-sortablejs';
import useUploadMedia from '../../hooks/useUploadMedia';
import Select from 'react-select'
import { FileUploader } from 'react-drag-drop-files';


const fileTypes = ["JPG", "PNG", "JPEG"];
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IS_REQUIRED = true
function EditProduct({ }) {
    const navigate = useNavigate()
    const { id } = useParams()
    const productImageUpload = useRef(null)
    const [brandListOptions, setBrandListOptions] = useState([])
    const [frameListOptions, setFrameListOptions] = useState([])
    const [genderListOptions, setGenderListOptions] = useState([])
    const [taxListOptions, setTaxListOptions] = useState([])
    const [materialListOptions, setMaterialListOptions] = useState([])
    const [slug, setSlug] = useState()
    const [slugCheck, setSlugCheck] = useState()
    const queryClient = useQueryClient()
    const initialState = {
        additional_product_image: [],
        product_name: '',
        mrp_price: '',
        selling_price: '',
        discount: '',
        stock: '',
        description: '',
        brand_id: '',
        gender_id: '',
        tax_id: '',
        frame_type_id: '',
        category_id: '',
        category_name: '',
        product_details: [],
        product_thumbnail: '',
        product_thumbnail_preview: '',
        images_of_product: [],
        images_of_product_preview: [],
        seo: {
            'url_key': "",
            'meta_title': "",
            'meta_keywords': "",
            'meta_description': "",
        },
        slug: '',
        has_lense: '',
        featured_product: '',
        material_id: ''
    }
    const [productData, setProductData] = useState(initialState)

    const { paramsObject: editProductData } = useReadUpdated({
        url: 'productedit',
        initialData: {
            product_id: id
        },
        onSuccess: (d) => {
            const editData = d.data
            let data = {
                ...editData,
                product_thumbnail_preview: editData?.product_thumbnail,
                additional_product_image: editData?.additional_product_image,
                seo: JSON.parse(editData?.seo),
                product_details: editData?.product_details,

            }
            setProductData(data)
        }
    })
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleAddFromData = (e, file, isFileObject, name) => {
        let data;
        e.preventDefault()
        if (e.target.name == "product_name") {
            // console.log(e)
            let newval = e.target.value
            newval = newval.replace(/\s+/g, '-').toLowerCase();
            let sanval = sanitizetext(newval)
            let data = {
                ...productData,
                slug: sanval,
                product_name: e.target.value
            }
            setProductData(data)
            setSlug({ ...data })
            let senddata = {
                slug: sanval
            }
            deBounce((e) => callapi(e, senddata), senddata)
            return;
        } else {
            if (file) {
                if (isFileObject) {
                    data = {
                        ...productData,
                        [name]: e
                    }
                } else {
                    let fileObject = e.target.files[0]
                    data = {
                        ...productData,
                        [e.target.name]: fileObject
                    }
                }
            } else {
                if (e.target.name === 'seo') {
                    let value = e.target.value
                    let tempName = e.target.attributes['data-name'].value
                    data = {
                        ...productData,
                        seo: {
                            ...productData?.seo,
                            [tempName]: value
                        }
                    }
                } else {
                    let value = e.target.value
                    data = {
                        ...productData,
                        [e.target.name]: value
                    }
                }
            }
        }
        setProductData(data)
    }

    const handleImageAltTextChange = (e, index) => {
        const value = e.target.value
        const temp = productData?.additional_product_image
        temp[index].image_alt = value
        setProductData({
            ...productData,
            additional_product_image: temp
        })

    }

    // const postProductDataToServer = useMutation((data) => editProduct(data), {
    //     onSuccess: (data) => {
    //         if (data.status === 200) {
    //             queryClient.invalidateQueries('productList')
    //             toast.success("Product Edited Successfully", {
    //                 position: "top-right",
    //                 duration: 3000
    //             })
    //             setTimeout(() => {
    //                 handleCloseAndReset()
    //             }, 2000)
    //         }
    //     },
    //     onError: () => {
    //         setIsError(true)
    //     }
    // })
    const { setDataToServer: postProductDataToServer } = useCreate({
        refreshUrl: 'productList',
        url: 'productupdate',
        onSuccess: (data) => {
            toast.success("Product Edited Successfully", {
                position: "top-right",
                duration: 3000
            })
            setTimeout(() => {
                handleCloseAndReset()
            }, 2000)
        },
        onError: (err) => {
            setIsError(true)
        }
    })
    function sanitizetext(intext) {
        const noSpecialChars = intext.replace(/[^a-zA-Z0-9\-]/g, '');
        // urlcheck(noSpecialChars)
        return noSpecialChars
    }
    const callapi = (e, data) => {
        checkSlugData.mutate(data)
    }
    const slugInput = (e) => {
        let val = e.target.value
        val = val.replace(/\s+/g, '-').toLowerCase();
        let sanval = sanitizetext(val)
        let data = {
            ...productData,
            slug: sanval
        }
        // setSlug(sanval)
        setProductData({ ...data })
        setSlug({ ...data })
        let senddata = {
            slug: sanval
        }
        deBounce((e) => callapi(e, senddata), senddata)
    }




    const handleCheckedChange = (e) => {
        let checked = e.target.checked
        let data = {
            ...productData,
            [e.target.name]: checked
        }
        setProductData(data)
    }

    const setImagePreview = (val) => {
        const newValue = { ...productData }
        newValue.additional_product_image = val
        setProductData(newValue)
    }

    const { setDataToServer: checkSlugData } = useCreate({
        refreshUrl: '',
        url: 'checkSlugData',
        onSuccess: (data) => {
            setSlugCheck(false)
            // setIsSuccess(true)
        },
        onError: (err) => {
            setSlugCheck(true)
            // setIsError(true)
        }
    })

    const closeErrorMessage = () => {
        setIsError(false)
    }
    const handleProductSubmit = (e) => {
        e.preventDefault()
        // const data = {...productData
        postProductDataToServer.mutate(productData)
    }
    const handleCloseAndReset = () => {
        closeErrorMessage()
    }
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])

    useReadUpdated({
        url: 'getFrameList',
        initialData: {},
        onSuccess: (data) => {
            const tempData = data.data
            setFrameListOptions(tempData)
        }
    })

    useReadUpdated({
        url: 'getBrandList',
        initialData: {},
        onSuccess: (data) => {
            const tempData = data.data
            setBrandListOptions(tempData)
        }
    })

    useReadUpdated({
        url: 'genderList',
        initialData: {},
        onSuccess: (data) => {
            const tempData = data.data
            setGenderListOptions(tempData)
        }
    })

    useReadUpdated({
        url: 'gettaxList',
        initialData: initialState,
        onSuccess: (data) => {
            setTaxListOptions(data.data)
        }
    })

    useQuery(['categoryList'], getCategoryList, {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setCategoryList(data.data.data)
        }
    })

    useReadUpdated({
        url: 'materialList',
        initialData: initialState,
        onSuccess: (data) => {
            setMaterialListOptions(data.data)
        }
    })
    let uploadScreen

    const { upload } = useUploadMedia({
        url: 'uploadImage',
        refreshUrl: '',
        onSuccess: (res) => {
            let copyProduct = { ...productData }
            if (uploadScreen === 'thumbnail') {
                copyProduct['thumbnail'] = { image_alt: '', ...res.data[0] }
            }
            else {
                let copyAdditionalProductImage = copyProduct['additional_product_image']
                copyAdditionalProductImage = [...copyProduct['additional_product_image'], ...res?.data?.map((addImg, index) => {
                    return { image_alt: '', ...addImg }
                })]
                copyProduct['additional_product_image'] = copyAdditionalProductImage
            }
            setProductData(copyProduct)
            toast.success("Image Uploaded Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })


    const handleUploadFile = (e, screen) => {
        uploadScreen = screen
        let data = {
            screen: 'products',
            thumbnail: [...e]
        }
        upload.mutate(data)
    }

    const handleChangeThumbImageAlt = (e) => {
        let productCopy = { ...productData }
        productCopy['thumbnail'][e.target.name] = e.target.value
        setProductData(productCopy)
    }


    const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
    const handleOpenUploadModal = () => {
        setOpenUploadImagesModal(true)
    }
    const handleCloseUploadImageModal = () => {
        setOpenUploadImagesModal(false)
    }
    const handleClickOpenFileDialog = () => {
        productImageUpload.current.click()
    }

    const handleRemoveSelectedProductImage = (e, id) => {
        e.stopPropagation();
        let predData = productData
        predData.additional_product_image.splice(id, 1)
        setProductData({ ...productData })
    }

    const [openConfigModal, setOpenConfigModal] = useState(false)
    const handleOpenConfigurationModal = () => {
        setOpenConfigModal(true)
    }

    const { queryData: configData } = useRead({
        url: 'getConfigList',
        initialData: {
            product_id: id,
        }
    })

    const handleSelectChange = (array, object) => {
        const { name } = object
        let data = {
            ...productData,
            [name]: array
        }
        setProductData(data)
    }

    return (
        <>
            {upload.isLoading &&
                <div className=' fixed top-0 left-0 w-full h-full bg-[#ffffffb0] flex items-center justify-center z-50'>
                    <Spinner />
                </div>}
            <Layout>
                <div className='container_xxl relative z-10'>
                    <div className='flex items-center space-x-4 headingBorder__b'>
                        <div>
                            <IconButton onClick={() => navigate('/products')}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Edit Product</h1>
                        </div>
                    </div>
                    {
                        editProductData.isLoading ? (
                            <Loader />
                        ) : (
                            <div className='form-body py-8'>
                                <form className='' autoComplete='off' onSubmit={handleProductSubmit}>
                                    <div className='flex items-center justify-between border-b border-gray-400'>
                                        <h1 className='font-semibold text-2xl pb-3'>Product Details</h1>
                                    </div>
                                    <div className='grid grid-cols-1 gap-4'>
                                        <div>
                                            <label htmlFor="Product Name" className='label'>Product Name <small className="text-red-700">*</small></label>
                                            <input required type="text" placeholder='Product Name' name='product_name' className='input' value={productData?.product_name} onChange={handleAddFromData} />
                                        </div>
                                        <div>
                                            <label htmlFor="Slug" className='label'>Slug <small className="text-red-700">*</small></label>
                                            <input required type="text" placeholder='Slug' name='slug' className='input' value={productData?.slug} onChange={slugInput} />
                                            {slugCheck ? <p class=" text-red-600 text-base mb-6 mt-6 font-semibold" id="slugerror">Slug already exist</p> : ""}
                                        </div>
                                        <div>
                                            <label htmlFor="Product Name" className='label'>Product Description</label>
                                            <textarea required className='input min-h-[10rem]' placeholder='Description' name='description' value={productData?.description} onChange={handleAddFromData}></textarea>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className=' space-y-7'>
                                            <div className={`grid grid-cols-5 gap-4`}>
                                                <div>
                                                    <label className='label'>MRP Price <small className="text-red-700">*</small></label>
                                                    <input required={true} type="number" placeholder='MRP Price' name='mrp_price' className='input' value={productData?.mrp_price} onChange={handleAddFromData} />
                                                </div>
                                                <div>
                                                    <label className='label'>Selling Price <small className="text-red-700">*</small></label>
                                                    <input required={true} type="number" placeholder='Selling Price' name='selling_price' className='input' value={productData?.selling_price} onChange={handleAddFromData} />
                                                </div>
                                                <div>
                                                    <label className='label'>Discount <small className="text-red-700">*</small></label>
                                                    <input required={true} type="number" placeholder='Discount' name='discount' className='input' value={productData?.discount} onChange={handleAddFromData} />
                                                </div>
                                                <div>
                                                    <label className='label'>Stock <small className="text-red-700">*</small></label>
                                                    <input required={true} type="number" placeholder='Stock' name='stock' className='input' value={productData?.stock} onChange={handleAddFromData} />
                                                </div>
                                                <div>
                                                    <label className='label'>Taxes <small className="text-red-700">*</small></label>
                                                    <select required={true} name="tax_id" className='input' value={productData?.tax_id} onChange={handleAddFromData}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            taxListOptions?.map((it) => {
                                                                return (
                                                                    <option key={it.tax_id} value={it.tax_id}>{it.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                            <h1 className='font-semibold text-2xl pb-3'>Product Filters</h1>
                                        </div>
                                        <div className=' space-y-7'>
                                            <div className={`grid grid-cols-3 gap-4`}>
                                                <div>
                                                    <label className='label'>Brand <small className="text-red-700">*</small></label>
                                                    <select required={true} name="brand_id" className='input' value={productData?.brand_id} onChange={handleAddFromData}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            brandListOptions?.map((it) => {
                                                                return (
                                                                    <option key={it.brand_id} value={it.brand_id}>{it.brand_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div>
                                                    <label className='label'>Frame <small className="text-red-700">*</small></label>
                                                    <select required={true} name="frame_type_id" className='input' value={productData?.frame_type_id} onChange={handleAddFromData}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            frameListOptions?.map((it) => {
                                                                return (
                                                                    <option key={it.frame_id} value={it.frame_id}>{it.frame_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div>
                                                    <label className='label'>Gender <small className="text-red-700">*</small></label>
                                                    <Select
                                                        name='gender'
                                                        isMulti
                                                        options={genderListOptions.map((option) => ({
                                                            label: option.gender_name,
                                                            value: option.gender_id,
                                                        }))}
                                                        value={productData?.gender}
                                                        onChange={handleSelectChange}
                                                    />
                                                </div>
                                                <div>
                                                    <label className='label'>Category <small className="text-red-700">*</small> </label>
                                                    <Select
                                                        name='category'
                                                        isMulti
                                                        options={categoryList.map((option) => ({
                                                            label: option.name,
                                                            value: option.id,
                                                        }))}
                                                        value={productData?.category}
                                                        onChange={handleSelectChange}
                                                    />
                                                </div>
                                                <div>
                                                    <label className='label'>Material <small className="text-red-700">*</small> </label>
                                                    <select required={true} name="material_id" className='input' value={productData?.material_id} onChange={handleAddFromData}>
                                                        <option value="">--Select--</option>
                                                        {
                                                            materialListOptions?.map((it) => {
                                                                return (
                                                                    <option key={it.id} value={it.id}>{it.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='flex space-x-10'>
                                                <div className=' space-x-2'>
                                                    <input type="checkbox" name="has_lense" checked={productData.has_lense} onChange={handleCheckedChange} />
                                                    <label className="label">Does This Product Has Lens ? </label>
                                                </div>
                                                <div className=' space-x-2'>
                                                    <input type="checkbox" name="featured_product" checked={productData.featured_product} onChange={handleCheckedChange} />
                                                    <label className="label">Is This  A Featured Product ? </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                            <h1 className='font-semibold text-2xl pb-3'>Configurations</h1>
                                            <div>
                                                <button onClick={handleOpenConfigurationModal} type='button' className='border-gray-800 bg-gray-800 text-white px-6 py-2 rounded mb-2'>
                                                    {
                                                        configData.isLoading ?
                                                            <Loader />
                                                            : configData?.data?.data.length > 0 ?
                                                                "Edit Configuration" : "Create Configuration"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                        <Configurations open={openConfigModal} editData={productData} defaultConfigData={configData?.data?.data || []} handleClose={() => setOpenConfigModal(false)} />
                                        {configData?.data?.data?.length > 0 && <GeneratedProducts products={configData?.data?.data} name={productData?.product_name} />}

                                    </div>
                                    <div>
                                        <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                            <h1 className='font-semibold text-2xl pb-3'>Search Engine Optimization</h1>
                                        </div>
                                        <div>
                                            <div className='mb-2'>
                                                <label className='label'>URL KEY </label>
                                                <input type="text" placeholder='URL KEY' name="seo" data-name='url_key' className='input' value={productData?.seo?.['url_key']} onChange={handleAddFromData} />
                                            </div>
                                            <div className='mb-2'>
                                                <label className='label'>Meta Title </label>
                                                <input type="text" placeholder='Meta Title' name="seo" data-name='meta_title' className='input' value={productData?.seo?.['meta_title']} onChange={handleAddFromData} />
                                            </div>
                                            <div className='mb-2'>
                                                <label className='label'>Meta Keywords </label>
                                                <input type="text" placeholder='Meta Keywords' name="seo" data-name='meta_keywords' className='input' value={productData?.seo?.['meta_keywords']} onChange={handleAddFromData} />
                                            </div>
                                            <div className='mb-2'>
                                                <label className='label'>Meta Description </label>
                                                <input type="text" placeholder='Meta Description' name="seo" data-name='meta_description' className='input' value={productData?.seo?.['meta_description']} onChange={handleAddFromData} />
                                            </div>
                                        </div>
                                    </div>
                                    <ProductDetails
                                        productData={productData}
                                        setProductData={setProductData}
                                    />
                                    <div className='grid grid-cols-2 gap-4'>
                                        <div>
                                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                                <div className='pb-3'>
                                                    <h1 className='font-semibold text-2xl'>Thumbnail of product</h1>
                                                    <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                                </div>
                                            </div>
                                            <div className=' space-y-4'>
                                                <FileUploader name="product_thumbnail" types={fileTypes} handleChange={(e) => handleUploadFile([e], 'thumbnail')} classes="file-uploader" hoverTitle='Drop here' />
                                                {productData?.thumbnail?.image_url && <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                                                    <img src={productData?.thumbnail?.image_url} alt="preview" />
                                                </div>}
                                                <div className='mb-2'>
                                                    <label className='label'>Thumbnail Image Alt Tag</label>
                                                    <input type="text" placeholder='Thumbnail Image Alt Tag' value={productData?.thumbnail?.image_alt} onChange={handleChangeThumbImageAlt} name="image_alt" className='input' />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                                                <div className='pb-3'>
                                                    <h1 className='font-semibold text-2xl'>Images of product</h1>
                                                    <small className='text-red-700'>Note:Image to be less than 1mb</small>
                                                </div>
                                            </div>
                                            <div className='flex items-center justify-center mt-12'>
                                                <Button onClick={handleOpenUploadModal} variant="contained" color='success' className='primaryBtn-contained'>Add Images</Button>
                                            </div>
                                        </div>
                                    </div>
                                    {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{postProductDataToServer?.error?.message}, Please try again later</Alert>}
                                    {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Product Updated Succesfully</Alert>}
                                    <div className='text-center my-6'>
                                        {postProductDataToServer.isLoading ?
                                            <Spinner /> :
                                            <Button type='submit' variant="contained" className='primaryBtn-contained'>
                                                Save Product
                                            </Button>}
                                    </div>
                                </form>
                            </div>
                        )
                    }
                </div>
            </Layout>
            <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
                    <div className=' max-w-5xl mx-auto bg-white'>
                        <div className=' divide-y'>
                            <div className="head-modal">
                                <div className='flex items-center space-x-4 px-6'>
                                    <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                                        <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                                        </svg>
                                    </div>
                                    <h2 className='heading text-3xl'>Upload Product Images</h2>
                                </div>
                            </div>
                            <div className="body-modal py-4 px-6">
                                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative cursor-pointer'>
                                    <div className='imagePreview__container relative z-10'>
                                        <div >
                                            <ReactSortable list={productData?.additional_product_image} setList={setImagePreview} className="grid grid-cols-4 gap-4 ">
                                                {productData?.additional_product_image?.map((img, idx) => {
                                                    const { image_url, image_alt } = img
                                                    return <div key={idx}>
                                                        <div className=' w-full overflow-hidden border p-2 relative cursor-default' key={idx}>
                                                            <div className='w-6 h-6 cursor-pointer float-right' >
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" onClick={(e) => handleRemoveSelectedProductImage(e, idx)} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </div>
                                                            <img src={image_url} alt="img" className='w-full h-40 object-contain' />
                                                            <div className='my-2'>
                                                                <label className='label'>Product {idx + 1} Alt Tag</label>
                                                                <input type="text" value={image_alt} placeholder={`Product ${idx + 1} Alt Tag`} onChange={(e) => handleImageAltTextChange(e, idx)} name="image_alt" className='input' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                                <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                                                    <GrAdd className=' w-16 h-16 text-gray-400' />
                                                    <small>Add Product Image</small>
                                                </div>
                                            </ReactSortable>
                                        </div>
                                    </div>
                                    <input type="file" className='hidden' ref={productImageUpload} onChange={(e) => handleUploadFile(e.target.files, 'additional_product_image')} multiple accept="image/jpeg, image/png, image/jpg" />
                                </div>
                            </div>
                            <div className="foot-modal py-4 text-center">
                                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default EditProduct