import { Button, Dialog, IconButton } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addCategory } from "../../apis/category";
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { GrAdd } from "react-icons/gr";
import useCreate from "../../hooks/useCreate";
import { toast } from "react-hot-toast";

const fileTypes = ["JPG", "PNG", "JPEG"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddBrand({ open, onClose }) {
  const queryClient = useQueryClient()
  const initialState = {
    brand_name: "",
    brand_url: ""
  }
  const [brandData, setBrandData] = useState(initialState)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleInputData = (e) => {
    let value = e.target.value
    let data = {
      ...brandData,
      [e.target.name]: value,
    }
    setBrandData(data)
  }

  const handleuploadAgain = () => {
    let data = {
      ...brandData,
      logo_preview: '',
      logo: ''
    }
    setBrandData(data)
  }

  const handleAddFromData = (e, file, isFileObject, name) => {
    let data;
    if (file) {
      if (isFileObject) {
        data = {
          ...brandData,
          [name]: e
        }
      } else {
        let fileObject = e.target.files[0]
        data = {
          ...brandData,
          [e.target.name]: fileObject
        }
      }
    } else {
      let value = e.target.value
      data = {
        ...brandData,
        [e.target.name]: value
      }
    }
    setBrandData(data)
  }


  const handleCloseAndReset = () => {
    onClose()
    setIsSuccess(false)
    setIsError(false)
    setBrandData(initialState)
  }

  const handleBrandCreateSuccess = () => {
    handleCloseAndReset()
    toast.success("Brand Added Successfully",{
      position:'top-right',
      duration:3000
    })
  }

  const handleBrandCreateError = () => {

  }


  const { setDataToServer: setBrandDataToServer } = useCreate({
    refreshUrl: 'getBrandList',
    url: 'createBrand',
    onSuccess: handleBrandCreateSuccess,
    onError: handleBrandCreateError
  })

  const handleAddBrand = (e) => {
    e.preventDefault()
    setBrandDataToServer.mutate(brandData)
  
  }

  const closeErrorMessage = () => {
    setIsError(false)
  }

  useEffect(() => {
    isError && setTimeout(() => setIsError(false), [4000])
  }, [isError])

  useEffect(() => {
    if (!brandData.logo) return
    const objectUrl = URL.createObjectURL(brandData.logo)
    let data = {
      ...brandData,
      logo_preview: objectUrl
    }
    setBrandData(data)
    return () => URL.revokeObjectURL(objectUrl)
  }, [brandData.logo])
  return (
    <>
      <Dialog fullScreen maxWidth={'md'} open={open} onClose={handleCloseAndReset} TransitionComponent={Transition}>
        <div className='container_xxl'>
          <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
            <div>
              <IconButton onClick={handleCloseAndReset}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div>
              <h1 className='heading'>Add Brand</h1>
            </div>
          </div>
          <div className='form-body py-8'>
            <form className=' space-y-4' onSubmit={handleAddBrand}>
              <div>
                <label htmlFor="Brand Name" className='label'>Brand Name <small className="text-red-700">*</small></label>
                <input required type="text" placeholder='Enter Brand' name='brand_name' className='input' value={brandData.brand_name} onChange={handleInputData} />
              </div>
              <div>
                <label htmlFor="Brand URL" className='label'>Brand URL <small className="text-red-700">*</small></label>
                <input required type="text" placeholder='Enter Brand URL' name='brand_url' className='input' value={brandData.brand_url} onChange={handleInputData} />
              </div>
              <div>
                <div className='my-7 flex items-center justify-between border-b border-gray-400'>
                  <div className='pb-3'>
                    <h1 className='font-semibold text-2xl'>Brand Logo</h1>
                    <small className='text-red-700'>Note:Image to be less than 1mb</small>
                  </div>
                </div>
                <div className=' space-y-4'>
                  {!brandData.logo_preview ?
                    <FileUploader name="logo" types={fileTypes} handleChange={(e) => handleAddFromData(e, true, true, 'logo')} classes="file-uploader" hoverTitle='Drop here' />
                    : <div className=' border border-dashed border-blue-700 rounded'>
                      <div className=' w-40 mx-auto py-4 text-center space-y-3'>
                        <img src={brandData.logo_preview} alt="preview" />
                        <Button variant="outlined" onClick={handleuploadAgain}>Re-Upload</Button>
                      </div>
                    </div>}
                </div>
              </div>
              {isError && <Alert onClose={closeErrorMessage} className='mb-4' severity="error" sx={{ width: '100%' }}>{setBrandDataToServer?.error?.message}, Please try again later</Alert>}
              {isSuccess && <Alert onClose={closeErrorMessage} className='mb-4' severity="success" sx={{ width: '100%' }}>Category Added Succesfully</Alert>}
              <div className=' space-x-4 text-center pb-5'>
                {setBrandDataToServer.isLoading ?
                  <Spinner /> :
                  <>
                    <Button type='submit' variant="contained">Save</Button>
                    <Button variant="outlined" onClick={handleCloseAndReset}>Close</Button>
                  </>}
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default AddBrand