import React, { useState } from 'react'

const SummaryQuantity = ({ products, setProducts }) => {
  const [type, setType] = useState('single')
  const [quantity, setQuantity] = useState(0)
  const handleChange = (e) => {
    setType(e.target.value)
  }

  const handleAllInputChange = (e) => {
    const val = e.target.value
    setQuantity(val)
    const temp = [...products]
    temp.forEach((element, idx) => {
      temp[idx].quantity = val
    });
    setProducts(temp)
  }

  return (
    <>
      <div className=' space-y-4' >
        <div className='flex'>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input type="radio" onChange={handleChange} value={'single'} name='price' checked={type === 'single'} />
            <p>Apply single quantity to all SKUs</p>
          </label>
          {type === 'single' && <input name="price" value={quantity} onChange={handleAllInputChange} className='w-16 input text-center ml-6' />}
        </div>
        <div>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input type="radio" onChange={handleChange} value={'unique'} name='price' checked={type === 'unique'} />
            <p>Apply unique quantity by attribute to each SKU</p>
          </label>
        </div>
        {/* <div>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input type="radio" value={'skip'} name='price' checked={type === 'skip'} />
            <p>Skip quantity at this time</p>
          </label>
        </div> */}
      </div>
    </>
  )
}

export default SummaryQuantity