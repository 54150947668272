import { useEffect, useState } from 'react'
import { BiPlus, BiSave } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import useCreate from '../../../../hooks/useCreate'
import { SuccessAlert } from '../../../alerts/Alerts'
import Loader from '../../../loader'

const AttributeValueGrid = ({ value, configData, dispatch }) => {
    const items = value.value
    const [attributeState, setAttributeState] = useState([...items])
    const [isCreatedSuccess, setIsCreatedSuccess] = useState(false)
    const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false)
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)

    const { setDataToServer: setCreateAttrValToServer } = useCreate({
        refreshUrl: 'getAttributeList',
        url: 'createAttributesValues',
        onSuccess: () => {
            setIsCreatedSuccess(true)
        },
        onError: () => { }
    })

    const { setDataToServer: setUpdateAttrValToServer } = useCreate({
        refreshUrl: 'getAttributeList',
        url: 'updateAttributesValues',
        onSuccess: () => {
            setIsUpdatedSuccess(true)
        },
        onError: () => { }
    })

    const { setDataToServer: setDeleteAttrValToServer } = useCreate({
        refreshUrl: 'getAttributeList',
        url: 'deleteAttributesValues',
        onSuccess: () => {
            setIsUpdatedSuccess(true)
        },
        onError: () => { }
    })

    useEffect(() => {
        const items = value.value
        setAttributeState([...items])
    }, [value])

    const handleChangeInput = (e, idx) => {
        const value = e.target.value
        const data = [...attributeState]
        data[idx]['attribute_value_name'] = value
        setAttributeState(data)
    }

    const handleAddMoreValues = () => {
        let data = {
            attribute_value_name: '',
        }
        setAttributeState(prev => [data, ...prev])
    }

    const handleSaveAttributeValue = (data, idx) => {
        if (data.attribute_value_id) {
            setUpdateAttrValToServer.mutate({
                ...data,
                ...value
            })
        } else {
            setCreateAttrValToServer.mutate({
                ...data,
                ...value
            })
        }
    }

    const handleDeleteAttributeValue = (data) => {
        // remove attr value from the state
        dispatch({
            type: 'remove_attr_val', payload: {
                attribute_id: value?.attribute_id,
                value: { ...data }
            }
        })
        // run delete function
        setDeleteAttrValToServer.mutate({
            ...data,
            ...value
        })
    }

    const handleSelectAllAttrVal = (e) => {
        const checked = e.target.checked
        if (checked) {
            attributeState.forEach((item) => {
                if (item?.attribute_value_id) {
                    dispatch({
                        type: 'select_attr_val', payload: {
                            attribute_id: value?.attribute_id,
                            value: { ...item }
                        }
                    })
                }
            })
        } else {
            attributeState.forEach((item) => {
                if (item?.attribute_value_id) {
                    dispatch({
                        type: 'remove_attr_val', payload: {
                            attribute_id: value?.attribute_id,
                            value: { ...item }
                        }
                    })
                }
            })
        }
    }

    const handleSelectAttrVal = (e, data) => {
        const checked = e.target.checked
        if (checked) {
            dispatch({
                type: 'select_attr_val', payload: {
                    attribute_id: value?.attribute_id,
                    value: { ...data }
                }
            })
        } else {
            dispatch({
                type: 'remove_attr_val', payload: {
                    attribute_id: value?.attribute_id,
                    value: { ...data }
                }
            })
        }
    }

    return (
        <>
            <div className='w-full border border-gray-200 rounded p-4 space-y-8'>
                <div className='flex items-center justify-between'>
                    <div className='flex items-center space-x-2'>
                        <p className='font-semibold text-xl capitalize'>{value.attribute_name}</p>
                        <p className=' text-sm'>{`(${attributeState.length} Options)`}</p>
                        {/* print length */}
                    </div>
                    <div>
                        <label className=' block cursor-pointer '>
                            <input
                                type="checkbox"
                                onChange={handleSelectAllAttrVal}
                                className='mr-2'
                                checked={Object.keys(configData[value.attribute_id]?.values).length === attributeState.length}
                            />
                            <span className='text-lg text-blue-500 font-semibold'>Select all</span>
                        </label>
                    </div>
                </div>
                <div className='flex items-center flex-wrap gap-3'>
                    <div className='' onClick={handleAddMoreValues}>
                        <div className=" border-2 border-dashed border-gray-500 cursor-pointer w-20 h-12 rounded hover:shadow-md flex items-center justify-center">
                            <BiPlus className=' w-6 h-6 text-gray-500' />
                        </div>
                        <small className='block text-center'>Add Values</small>
                    </div>
                    {attributeState.map((val, idx) => {
                        const { attribute_value_name, attribute_value_id } = val
                        return <div>
                            <label key={idx} className=' bg-gray-100 p-3 cursor-pointer hover:bg-gray-200 rounded flex items-center'>
                                {attribute_value_id && <input
                                    type="checkbox"
                                    onChange={(e) => handleSelectAttrVal(e, val)}
                                    className='mr-2'
                                    checked={attribute_value_id in configData[value.attribute_id].values}
                                />}
                                <input placeholder='Enter value' type="text" onChange={(e) => handleChangeInput(e, idx)} value={attribute_value_name} className='input' />
                            </label>
                            <div className='grid grid-cols-2 gap-x-1 mt-1'>
                                <div onClick={() => handleSaveAttributeValue(val, idx)} className=' flex items-center justify-center cursor-pointer p-2 bg-blue-200 rounded'>
                                    {/* {(setUpdateAttrValToServer.isLoading || setCreateAttrValToServer.isLoading) ? (
                                        <Loader className={''} />
                                    ) : <BiSave className=' w-4 h-4' />} */}
                                    <BiSave className=' w-4 h-4' />
                                </div>
                                <div onClick={() => handleDeleteAttributeValue(val)} className=' flex items-center justify-center cursor-pointer p-2 bg-red-200 rounded'>
                                    <RiDeleteBin6Line className=' w-4 h-4 text-red-700' />
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <SuccessAlert visible={isCreatedSuccess} onClose={() => setIsCreatedSuccess(false)} text={'Attribute Value Item Create Succesfully'} />
            <SuccessAlert visible={isUpdatedSuccess} onClose={() => setIsUpdatedSuccess(false)} text={'Attribute Value Item Updated Succesfully'} />
            <SuccessAlert visible={isDeletedSuccess} onClose={() => setIsDeletedSuccess(false)} text={'Attribute Value Item Deleted Succesfully'} />
        </>
    )
}

export default AttributeValueGrid