import { Modal } from '@mui/material'
import useCreate from '../../../../hooks/useCreate'
import { useState } from 'react'
import Loader from '../../../loader'

const EditAttributes = ({ open, onClose, setIsAttrEditedSuccess, editData }) => {
  const [attributeState, setAttributeState] = useState(editData)
  const { setDataToServer } = useCreate({
    refreshUrl: 'getAttributeList',
    url: "updateAttributes",
    onSuccess: () => {
      onClose()
      setIsAttrEditedSuccess(true)
    }
  })
  const handleChangeAttribute = (e) => {
    let $this = e.currentTarget
    let data = {
      ...attributeState,
      [$this.name]: $this.value
    }
    setAttributeState(data)
  }
  const handleSubmitAttribute = (e) => {
    e.preventDefault()
    // console.log(attributeState)
    setDataToServer.mutate(attributeState)
  }
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <div className=' absolute top-10 left-1/2 -translate-x-1/2 bg-white w-[40%] rounded-md'>
        <div className=' divide-y divide-gray-400'>
          <div>
            <h1 className='px-4 text-xl font-semibold py-3'>Edit Attribute</h1>
          </div>
          <div className='px-4 py-3'>
            <form className=' space-y-3'>
              <div>
                <label className='label'>Default Label <small className="text-red-700">*</small></label>
                <input required type="text" onChange={handleChangeAttribute} placeholder='Default Label' value={attributeState.attribute_name} name='attribute_name' className='input' />
              </div>
              <div>
                <label className='label'>Default Code <small className="text-red-700">*</small></label>
                <input required type="text" onChange={handleChangeAttribute} placeholder='Default Code' value={attributeState.attribute_code} name='attribute_code' className='input' />
              </div>
              <div className='text-center space-x-4'>
                <button type='button' onClick={handleSubmitAttribute} className="px-6 py-2 rounded text-white bg-[color:var(--color3)] border border-[color:var(--color3)]">
                  <div className='flex items-center justify-center space-x-2'>
                    <span>Save Attribute</span>
                    {setDataToServer.isLoading && <Loader />}
                  </div>
                </button>
                <button type='button' onClick={onClose} className="px-6 py-2 rounded border-gray-700 border disabled:cursor-not-allowed disabled:opacity-50">
                  <div className='flex items-center justify-center space-x-2'>
                    <span>Back</span>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default EditAttributes