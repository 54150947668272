import { useState } from 'react'
import CreateAttributes from './attributes/CreateAttributes'
import useRead from '../../../hooks/useRead'
import Spinner from '../../spinner'
import { SuccessAlert } from '../../alerts/Alerts'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useCreate from '../../../hooks/useCreate'
import EditAttributes from './attributes/EditAttributes'

const intialAttributeData = {
  "attribute_id": "",
  "attribute_code": "",
  "attribute_name": ""
}

const SelectAttributes = ({ configData, dispatch }) => {
  const initialState = {
    pageNo: 0,
    limit: 20,
  }
  const [attrData, setAttrData] = useState([])
  const [isAttrAddedSuccess, setIsAttrAddedSuccess] = useState(false)
  const [isAttrEditedSuccess, setIsAttrEditedSuccess] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editData, setEditData] = useState(null)
  const handleOpenCreateModal = () => {
    setIsModalOpen(true)
  }

  const { queryData } = useRead({
    url: 'getAttributeList',
    initialData: initialState,
    onSuccess: (data) => {
      setAttrData(data.data)
    }
  })

  const handleEditAttribute = (data) => {
    setIsEditModalOpen(true)
    setEditData(data)
  }

  const handleSelectAllAttr = (e) => {
    const checked = e.target.checked
    if (checked) {
      attrData.forEach((it) => {
        dispatch({ type: 'select_attr', payload: it })
      })
    } else {
      attrData.forEach((it) => {
        dispatch({ type: 'remove_attr', payload: it.attribute_id })
      })
    }
  }

  const handleSelectAttr = (checked, data) => {
    if (checked) {
      dispatch({ type: 'select_attr', payload: data })
    } else {
      dispatch({ type: 'remove_attr', payload: data.attribute_id })
    }
  }

  return (
    <>
      <div className=' space-y-6'>
        <div className='flex items-center justify-between'>
          <div>
            <h1 className='text-gray-700 font-semibold text-3xl'>Step 1 : Select Attributes</h1>
            {/* <p className='text-gray-700 text-sm py-2'>Selected Attributes: Size, Color etc</p> */}
          </div>
          <div>
            <button onClick={handleOpenCreateModal} className="px-6 py-2 rounded text-white bg-blue-500">
              <div className='flex items-center justify-center space-x-2'>
                <span>Create New Attribute</span>
              </div>
            </button>
          </div>
        </div>
        {queryData.isLoading
          ? <Spinner />
          : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                    <label className='flex items-center cursor-pointer select-none'>
                      <input type="checkbox" onChange={handleSelectAllAttr} checked={attrData.length === Object.keys(configData).length} className='mr-2' />
                      <span>Select All</span>
                    </label>
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Attribute Code
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Attribute Name
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Required
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    System
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Visible
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Scope
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {queryData?.data?.data.map((productData, idx) => {
                  const { attribute_code, attribute_name, required, system, visible, scope, attribute_id } = productData
                  return <tr key={idx}>
                    <td className="border-dashed border-t border-gray-200 userId">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                        <label className=' cursor-pointer select-none'>
                          <input type="checkbox" className='mr-2' checked={attribute_id in configData ? true : false} onChange={(e) => handleSelectAttr(e.target.checked, productData)} />
                          <span className='inline-block ml-6'>{idx + 1}</span>
                        </label>
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <div className='mx-auto p-4 text-center'>
                        <span>{attribute_code}</span>
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <div className='mx-auto p-4 text-center'>
                        <span className="text-gray-700 px-6 py-3 text-center text-sm">
                          {attribute_name}
                        </span>
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <div className='mx-auto p-4 text-center'>
                        <span className="text-gray-700 px-6 py-3 text-center text-sm">
                          {required}
                        </span>
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName">
                      <div className='mx-auto p-4 text-center'>
                        <span className="text-gray-700 px-6 py-3 text-center text-sm">
                          {system}
                        </span>
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 emailAddress">
                      <div className='mx-auto p-4 text-center'>
                        <span className="text-gray-700 px-6 py-3 text-center text-sm">
                          {visible}
                        </span>
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                      <div className='mx-auto p-4 text-center'>
                        <span className="text-gray-700 px-6 py-3 text-center text-sm">
                          {scope}
                        </span>
                      </div>
                    </td>
                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                      <div className='flex items-center space-x-5 justify-center'>
                        <div>
                          <IconButton onClick={() => handleEditAttribute(productData)}>
                            <EditIcon />
                          </IconButton>
                        </div>
                      </div>
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>}
      </div>
      {isModalOpen && <CreateAttributes
        open={isModalOpen}
        isAttrAddedSuccess={isAttrAddedSuccess}
        setIsAttrAddedSuccess={setIsAttrAddedSuccess}
        onClose={() => setIsModalOpen(false)}
      />}
      {isEditModalOpen && <EditAttributes
        open={isEditModalOpen}
        setIsAttrEditedSuccess={setIsAttrEditedSuccess}
        onClose={() => setIsEditModalOpen(false)}
        editData={editData}
      />}
      <SuccessAlert visible={isAttrAddedSuccess} onClose={setIsAttrAddedSuccess} text={"Atttribute Added Successfully"} />
      <SuccessAlert visible={isAttrEditedSuccess} onClose={setIsAttrEditedSuccess} text={"Atttribute Updated Successfully"} />

    </>
  )
}

export default SelectAttributes