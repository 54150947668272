import React, { useState } from 'react'
import Layout from '../../components/layout'
import useRead from '../../hooks/useRead'
import Spinner from '../../components/spinner'
import useCreate from '../../hooks/useCreate'
import { Alert, Button } from '@mui/material'

const LenSizeMapping = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [lensList, setLensList] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [mapList, setMapList] = useState({})

  const { setDataToServer: createMap } = useCreate({
    refreshUrl: 'getMapList',
    url: 'createlensSizeMap',
    onSuccess: (data) => {
      setIsError(false)
      setIsSuccess(true)
      setTimeout(() => {
        setIsSuccess(false)
      }, 4000)
    },
    onError: () => {
      setIsSuccess(false)
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 4000)
    }
  })

  const { queryData: handleLensList } = useRead({
    url: 'getLensList',
    initialData: {},
    onSuccess: ({ data }) => {
      setLensList(data)
    }
  })

  const { queryData: handleSizeList } = useRead({
    url: 'getSizeList',
    initialData: {},
    onSuccess: ({ data }) => {
      setSizeList(data)
    }
  })

  const { queryData: handleMapList } = useRead({
    url: 'getMapList',
    initialData: {
      limit: 150
    },
    onSuccess: ({ data }) => {
      const tempObject = {}
      data.map((i) => {
        const { lens_id, size_id } = i
        return tempObject[lens_id + "-" + size_id] = {
          ...i
        }

      })
      setMapList(tempObject)
    }
  })

  const handleMapChange = (e, lens_id, size_id) => {
    const temp = structuredClone(mapList)
    if (temp[lens_id + "-" + size_id]) {
      temp[lens_id + "-" + size_id] = {
        ...temp[lens_id + "-" + size_id],
        price: e.target.value
      }
    } else {
      temp[lens_id + "-" + size_id] = {
        price: e.target.value,
        lens_id,
        size_id
      }
    }

    setMapList(temp)
  }

  const saveMap = () => {
    const temp = []
    Object.keys(mapList).map((map) => {
      temp.push(mapList[map])
    })
    createMap.mutate({
      map_data: JSON.stringify(temp)
    })
  }

  const closeErrorMessage = () => {
    setIsError(false)
    setIsSuccess(false)
  }

  return (
    <Layout>
      <div className='category'>
        <div className='category__head headingBorder__b'>
          <h2 className='titleHeading'>Lens Sizing Mapping</h2>
        </div>
      </div>
      <div>
        {
          (handleLensList.isLoading || handleSizeList.isLoading || handleMapList.isLoading) ? (
            <Spinner />
          ) : <div>
            <table className='mt-8 bg-white border border-gray-500 border-collapse w-full mb-8'>
              <thead>
                <tr>
                  <td className='border-gray-500'></td>
                  {
                    sizeList.map(({ name, size_id }) => {
                      return <td key={size_id} className='py-3 px-4 border bg-[color:var(--color2)] text-center text-white border-gray-500'>{name}</td>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  lensList.map(({ name, lens_id }) => {
                    return (
                      <tr>
                        <td key={lens_id} className='py-3 px-4 border bg-[color:var(--color2)] text-center text-white border-gray-500'>{name}</td>
                        {
                          sizeList.map(({ size_id }) => {
                            return <td key={size_id} className='border border-gray-500'>
                              <input
                                value={mapList[lens_id + "-" + size_id]?.price || ''}
                                onChange={(e) => handleMapChange(e, lens_id, size_id)}
                                className='w-full h-full py-3 px-4 focus:outline-none text-center'
                              />
                            </td>
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <Button onClick={saveMap} type='button' variant="contained" className='mt-8'>Save {createMap.isLoading ? <Spinner className={'h-3 w-3'} /> : ''}</Button>
          </div>
        }
        {isError && <Alert onClose={closeErrorMessage} className='my-4' severity="error" sx={{ width: '100%' }}>Something went wrong!!!</Alert>}
        {isSuccess && <Alert onClose={closeErrorMessage} className='my-4' severity="success" sx={{ width: '100%' }}>Mapping Saved Succesfully</Alert>}
      </div>
    </Layout>
  )
}

export default LenSizeMapping