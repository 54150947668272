import React, { useState } from 'react'
import Layout from '../../components/layout';
import { useQuery } from '@tanstack/react-query';
import useRead from '../../hooks/useRead';
import Spinner from '../../components/spinner';
import Errors from '../errors';
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import useCreate from '../../hooks/useCreate';
import EditFrame from './EditFrame';
import AddFrame from './AddFrame';
import Pagination from '../../components/elements/Pagination/Pagination';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const Frames = () => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [editData, setEditData] = useState(null)
  const [brands, setBrand] = useState([])
  const [searchParams,setSearchParams] = useSearchParams()
  
  const initialData = {
    limit:10,
    pageNo:searchParams.get('pageNo') || 0
  }
  const [currentPage,setCurrentPage] = useState(parseInt(initialData.pageNo));

  const {queryData:handleGetProductList,paramsObject,setGetListParams} = useRead({
    url: 'getFrameList',
    initialData: {}
  })

  const total_count = handleGetProductList?.data?.count

  const handleAddBrand = () => {
    setIsOpenAddModal(true)
  }

  const handleEditBrand = (data) => {
    setIsOpenEditModal(true)
    setEditData({ ...data })
  }

  const handleCloseAddModal = () => {
    setIsOpenAddModal(false)
  }

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false)
    setEditData(null)
  }
  
  const handlePageClick = (val) => {
    window.scrollTo({
      top:0,
      behavior:'smooth'
  })
    let data = {
      ...paramsObject,
      pageNo:val.selected
    }
    setGetListParams(data)
    setCurrentPage(val.selected)
    setSearchParams(createSearchParams({
      pageNo:val.selected
    }))
  }

  const { setDataToServer: setDeleteFrame } = useCreate({
    refreshUrl: 'getFramesList',
    url: 'deleteFrame',
    onSuccess: () => { },
    onError: () => { }
  })

  const handleDeleteBrand = (id) => {
    setDeleteFrame.mutate({
      frame_id: id
    })
  }

  return (
    <>
      <Layout>
        <div className='category'>
          <div className='category__head headingBorder__b'>
            <h2 className='titleHeading'>Frames</h2>
          </div>
          <div className='my-4 flex justify-end'>
            <Button onClick={handleAddBrand} variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
              Add Frame
            </Button>
          </div>
        </div>
        <div>
          {handleGetProductList?.isLoading
            ? <Spinner />
            : handleGetProductList?.status === "error"
              ? <Errors errorObject={handleGetProductList?.error} inline />
              : <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                        Sr.no
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Frame
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {handleGetProductList?.data?.data.map((productData, idx) => {
                      const { frame_id, frame_name } = productData
                      return <tr key={idx}>
                        <td className="border-dashed border-t border-gray-200 userId">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {initialData.limit * initialData.pageNo + idx + 1}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName text-center">
                          {frame_name}
                        </td>
                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                          <div className='flex items-center space-x-5 justify-center'>
                            <div>
                              <IconButton onClick={() => handleEditBrand(productData)}>
                                <EditIcon />
                              </IconButton>
                            </div>
                            <div onClick={() => handleDeleteBrand(frame_id)}>
                              <IconButton>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </div>
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              </div>}
            <div className='my-7 flex justify-center items-center'>
            <Pagination
                currentPage={currentPage}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(total_count/initialData.limit)}
                limit={initialData.limit}
                />
            </div>
        </div>
      </Layout>
      <AddFrame open={isOpenAddModal} onClose={handleCloseAddModal} />
      <EditFrame open={isOpenEditModal} onClose={handleCloseEditModal} editData={editData} />
    </>
  )
}

export default Frames