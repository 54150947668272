import React,{useState} from 'react'
import ReactPaginate from 'react-paginate'
function Pagination({ lengthofItems, onPageChange, limit, currentPage, pageCount }) {
    const [value, setValue] = useState({
      selected: currentPage + 1,
    });
  
    // const handleValueChange = (e) => {
    //   setValue({ selected: e.target.value });
    // };
  
    // const handleInputPageChange = (e) => {
    //   e.preventDefault();
    //   onPageChange({
    //     selected: parseInt(value.selected) - 1,
    //   });
    //   return;
    // };
  
    return (
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={onPageChange}
          pageRangeDisplayed={10}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          containerClassName="flex items-center justify-center space-x-4"
          pageLinkClassName="pageNumber"
          previousLinkClassName="pageNumber"
          nextLinkClassName="pageNumber"
          activeLinkClassName="selectedPageNumber"
          disabledClassName="lastPage"
          disabledLinkClassName="lastPage"
          forcePage={currentPage}
          
        />
    
    );
  }

export default Pagination