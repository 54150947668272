import React, { useState } from 'react'
import { Dialog, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Slide from '@mui/material/Slide'
import useReadUpdated from '../../hooks/updated/useRead'
import { useMutation } from '@tanstack/react-query'
import { getOrderPdf } from '../../apis/orders'
import spinner from '../../assets/images/common/spinner.gif'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})
const OrderDetails = ({ open, onClose, id }) => {

    const { list } = useReadUpdated({
        method: "POST",
        url: 'single_order_details',
        initialData: {
            id
        }
    })
    const orderReportDownload = useMutation((data) => getOrderPdf(data), {
        onSuccess: (data) => {
            console.log(data);
            let url = window.URL.createObjectURL(data.data);
            Object.assign(document.createElement("a"), {
                target: "_blank",
                rel: "noopener noreferrer",
                href: url,
            }).click();
        },
        onError: () => { },
    });

    const handleViewOrderPdf = (id) => {
        let data = {
            id: id,
        };
        orderReportDownload.mutate(data);
    };

    const power = {
        1: 'Zero',
        2: 'Bifocal',
        3: 'Single'
    }
    const subTotal = list.data?.order[0]?.order_details?.reduce((res,price)=>{
        const total =parseFloat(price.price) * parseFloat(price.qty)
         return res + total  
    },0)
    return (
        <>
            <Dialog fullScreen maxWidth={'md'} open={open} onClose={onClose} TransitionComponent={Transition}>
                {orderReportDownload.isLoading && <div className='fixed w-full h-full  z-50 top-0 left-0 bg-[#ffffff7d]'><img src={spinner} alt="" className="w-[5rem] h-[5rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /></div>}

                <div className='container_xxl'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={onClose}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading '>Order Details</h1>
                        </div>
                    </div>
                    <div className='form-body py-8'>
                        <div className='flex justify-end w-full px-10'>
                            <button onClick={() =>handleViewOrderPdf(id)} className='text-lg bg-gray-100 px-5 py-2 rounded-md flex space-x-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                                </svg>
                                <span>
                                    Download Report
                                </span>
                            </button>
                        </div>
                        {/* <div className='flex flex-col space-y-5 mt-10'>
                            <div className='flex  items-center space-x-5'>
                                <h1 className=' font-semibold text-lg'>Customer Name: </h1>
                                <p>{list.data?.customer?.name}</p>
                            </div>
                            <div className='flex w-full space-x-32'>
                                <div className='flex  items-center space-x-5'>
                                    <h1 className=' font-semibold text-lg'>Customer Phone: </h1>
                                    <p>{list.data?.customer?.phone_number}</p>
                                </div>
                                {
                                    list.data?.order?.map((order) => {

                                        return (
                                            <div className='flex  items-center space-x-5'>
                                                <h1 className=' font-semibold text-lg'>Order Id: </h1>
                                                <p>{order.order_id}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className=' flex items-center space-x-5'>
                                <h1 className='font-semibold text-lg'>Email: </h1>
                                <p className=' whitespace-preline'>{list?.data?.customer?.address?.email}</p>
                            </div>
                            <div className=' flex items-center space-x-5'>
                                <h1 className='font-semibold text-lg'>Address: </h1>
                                <p className=' whitespace-preline'>{list?.data?.customer?.address?.address1}</p>
                            </div>
                            <div className=' flex w-full space-x-32'>
                                <div className='flex items-center space-x-5'>
                                    <h1 className='font-semibold text-lg'>City: </h1>
                                    <p className=' whitespace-preline'>{list?.data?.customer?.address?.city}</p>
                                </div>
                                <div className='flex items-center space-x-5'>
                                    <h1 className='font-semibold text-lg'>State: </h1>
                                    <p className=' whitespace-preline'>{list?.data?.customer?.address?.state}</p>
                                </div>
                            </div>
                            <div className='flex  items-center space-x-5'>
                                <h1 className=' font-semibold text-lg'>Prescription: </h1>
                            </div>
                            {
                                list?.data?.order[0]?.order_details?.map((lens) => {
                                    return (
                                        <>
                                         <div className='flex  items-center space-x-5'>
                                            <h1 className='font-semibold'>Power: </h1>
                                            <p>{power[lens.power]}</p>
                                            </div>
                                            {
                                                lens?.lens_data?.map((data) => {
                                                  
                                                    return (
                                                        <>
                                                            <div className='flex  items-center space-x-5'>
                                                                <h1 className='font-semibold'>Lens Name: </h1>
                                                                <p>{data?.lens_name}</p>
                                                            </div>
                                                            <div>
                                                                {
                                                                    power[lens?.power] === 'Zero' ? (
                                                                        <></>
                                                                    ):
                                                                    (
                                                                        data?.image_url === ''  ? (
                                                                        <>
                                                                                
                                                                                    <div className=' gap-5 grid grid-cols-2'>
                                                                                        <div className='flex  items-center space-x-5'>
                                                                                            <h1 className='font-semibold'>Distance: </h1>
                                                                                            <table className='border-2 border-black w-1/2  text-center '>
                                                                                                <thead>
                                                                                                    <th colSpan={1} className='  border-2 border-black'></th>
                                                                                                    <th className='border-2 px-5 border-black'>Left Eye</th>
                                                                                                    <th className='border-2 px-5 border-black'>Right Eye</th>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className='border-2  border-black'>Axis</td>
                                                                                                        <td className=' border-2 border-black'>{data.prescription?.distance?.left_eye?.axis || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data.prescription?.distance?.right_eye?.axis || '---'}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Cylinderical</td>
                                                                                                        <td className='border-2 border-black'>{data.prescription?.distance?.left_eye?.cylinderical || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data.prescription?.distance?.right_eye?.cylinderical || '---'}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Spherical</td>
                                                                                                        <td className='border-2 border-black'>{data.prescription?.distance?.left_eye?.spherical || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data.prescription?.distance?.right_eye?.spherical || '---'}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div className='flex  items-center space-x-5'>
                                                                                            <h1 className='font-semibold'>Reading: </h1>
                                                                                            <table className='border-2 border-black w-1/2   text-center'>
                                                                                                <thead>
                                                                                                    <th colSpan={1} className='  border-2 border-black'></th>
                                                                                                    <th className='border-2 px-5 border-black'>Left Eye</th>
                                                                                                    <th className='border-2 px-5 border-black'>Right Eye</th>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Axis</td>
                                                                                                        <td className='border-2 border-black'>{data?.prescription?.reading?.left_eye?.axis || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data?.prescription?.reading?.right_eye?.axis || '---'}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Cylinderical</td>
                                                                                                        <td className='border-2 border-black'>{data?.prescription?.reading?.left_eye?.cylinderical || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data?.prescription?.reading?.right_eye?.cylinderical || '---'}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Spherical</td>
                                                                                                        <td className='border-2 border-black'>{data?.prescription?.reading?.left_eye?.spherical || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data?.prescription?.reading?.right_eye?.spherical || '---'}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>            
                                                                                        </div>
                                                                                        <div className='flex  items-center space-x-9'>
                                                                                            <h1 className='font-semibold'>Single: </h1>
                                                                                            <table className='border-2 border-black  w-1/2 text-center'>
                                                                                                <thead>
                                                                                                    <th colSpan={1} className='  border-2 border-black'></th>
                                                                                                    <th className='border-2 px-5 border-black'>Left Eye</th>
                                                                                                    <th className='border-2 px-5 border-black'>Right Eye</th>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Axis</td>
                                                                                                        <td className='border-2 border-black'>{data?.prescription?.single?.left_eye?.axis || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data?.prescription?.single?.right_eye?.axis || '---'}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>
            
                                                                                                            Cylinderical
                                                                                                        </td>
                                                                                                        <td className='border-2 border-black'>{data?.prescription?.single?.left_eye?.cylinderical || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data?.prescription?.single?.right_eye?.cylinderical || '---'}</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className='border-2 border-black'>Spherical</td>
                                                                                                        <td className='border-2 border-black'>{data?.prescription?.single?.left_eye?.spherical || '---'}</td>
                                                                                                        <td className='border-2 border-black'> {data?.prescription?.single?.right_eye?.spherical || '---'}</td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>            
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ) : ( 
                                                                                <>
                                                                                    <div className='flex items-center space-x-5'>
                                                                                        <h1 className='font-semibold'>Prescription Image: </h1>
                                                                                        <img src={data?.image_url} alt="" className='w-52 h-40' />
                                                                                    </div>                                                                                 
                                                                                </> 
                                                                             )                                                              
                                                                    )
                                                                }
                                                            </div>                                                       
                                                       </>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                })
                            }
                            <div className='flex  items-center '>
                                <h1 className=' font-semibold text-lg'>Order Details: </h1>
                            </div>
                            <table className='px-5'>
                                <thead className=' text-center border-b-2 border-b-black'>
                                    <th>
                                        Sr.No
                                    </th>
                                    <th>
                                        Product Name
                                    </th>
                                    <th>
                                        Price
                                    </th>
                                    <th>
                                        Quantity
                                    </th>
                                    <th>
                                        Total
                                    </th>
                                </thead>
                                <tbody>
                                    {
                                        list.data?.order[0]?.order_details?.map((order, idx) => {
                                            return (
                                                <tr className='text-center border-b-2 border-dashed odd:bg-white even:bg-black ' key={idx}>
                                                    <td className='py-3'>
                                                        {idx + 1}
                                                    </td>
                                                    <td className='py-3 px-10'>
                                                        {order.name}
                                                    </td>
                                                    <td className='py-3'>
                                                        {order.price}
                                                    </td>
                                                    <td className='py-3'>
                                                        {order.qty}
                                                    </td>
                                                    <td className='py-3'>
                                                        {order.price * order.qty}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td className=' text-center border-b-2 border-dashed px-4 py-3' colSpan={3} >
                                            Lens Price
                                        </td>
                                        <td className=' text-center border-b-2 border-dashed px-4 py-3' colSpan={1} >
                                        </td>
                                        <td className=' border-b-2 border-dashed  text-center py-3 ' colSpan={1}>
                                            {list.data?.len_price}
                                        </td>
                                    </tr>
                                    <tr className='border-b-2 border-black'>
                                        <td className='px-12 py-3 font-bold text-lg' colSpan={4}>
                                            Total
                                        </td>
                                        <td className='px-12 py-3 text-center' colSpan={1}>

                                            {
                                                list.data?.order?.map((total) => {
                                                    return (
                                                        <>
                                                            {total.total_sum}
                                                        </>
                                                    )
                                                })
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='flex justify-between items-center w-full'>
                                {
                                    list.data?.order?.map((order) => {

                                        return (
                                            <div className='flex  items-center space-x-5'>
                                                <h1 className=' font-semibold text-lg'>Reference No: </h1>
                                                <p>{order.order_token}</p>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    list.data?.order?.map((order) => {
                                        return (
                                            <div className='flex items-center space-x-5'>
                                                <h1 className=' font-semibold text-lg'>Ordered At:</h1>
                                                <p>{order.ordered_at} </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}

                        <div className='space-y-10'>
                            <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Order & Account Information</h1>
                                </div>
                                <div className='flex justify-evenly mt-5 w-full space-x-10 '>
                                    <div className='w-full space-y-5'>

                                        <div className='font-semibold text-xl'>
                                            Order
                                        </div>
                                        <div>

                                            <div className='w-full flex justify-between py-4 px-4 bg-gray-100 '>
                                                <p className='font-semibold'>Order Date</p>
                                                {
                                                    list.data?.order?.map((order) => {
                                                        return (
                                                            <>
                                                                <p>{order.ordered_at} </p>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='w-full flex justify-between py-4 px-4 bg-white'>
                                                <p className='font-semibold'>Order Status</p>
                                                {
                                                    list.data?.order?.map((order) => {
                                                        return (
                                                            <>
                                                                <p>{order.payment_status} </p>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='space-y-5 w-full'>
                                        <div className='font-semibold text-xl'>
                                            Account Information
                                        </div>
                                        <div>
                                            <div className=' w-full flex justify-between py-4 px-4 bg-gray-100'>
                                                <p className='font-semibold'>Customer Name</p>
                                                <p>{list?.data?.customer?.name}</p>
                                            </div>
                                            <div className=' w-full flex justify-between py-4 px-4'>
                                                <p className='font-semibold'>Email</p>
                                                <p>{list?.data?.customer?.billing_address.email}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Address Information</h1>
                                </div>
                                <div className='flex space-x-24 mt-5 font-semibold text-xl'>
                                    <div>
                                        Billing Address
                                    </div>
                                    <div>
                                        Shipping Address
                                    </div>
                                </div>
                            </div> */}
                            <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Address Information</h1>
                                </div>
                                <div className='flex justify-evenly mt-5 w-full space-x-10 '>
                                    <div className='w-full space-y-5'>

                                        <div className='font-semibold text-xl'>
                                           Billing Address
                                        </div>
                                        <div>
                                            <p className='font-bold'>{list.data?.customer?.billing_address?.first_name} {list.data?.customer?.billing_address?.last_name}</p>
                                           <p> {list.data?.customer?.billing_address?.address1}</p>
                                           <p>{list.data?.customer?.billing_address?.landmark}</p>
                                           <p>{list.data?.customer?.billing_address?.city} - {list.data?.customer?.billing_address?.pincode}</p>
                                           <p>{list.data?.customer?.billing_address?.state}</p>
                                           <p>Phone No. : {list.data?.customer?.billing_address?.phone}</p>
                                        </div>
                                    </div>
                                    <div className='space-y-5 w-full'>
                                        <div className='font-semibold text-xl'>
                                            Shipping Address
                                        </div>
                                        <div>
                                        <div>
                                            <p className='font-bold'>{list.data?.customer?.shipping_address?.first_name} {list.data?.customer?.shipping_address?.last_name}</p>
                                           <p> {list.data?.customer?.shipping_address?.address1}</p>
                                           <p>{list.data?.customer?.shipping_address?.landmark}</p>
                                           <p>{list.data?.customer?.shipping_address?.city} - {list.data?.customer?.billing_address?.pincode}</p>
                                           <p>{list.data?.customer?.shipping_address?.state}</p>
                                           <p>Phone No. : {list.data?.customer?.shipping_address?.phone}</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Payment & Shipping Method</h1>
                                </div>
                                <div className='flex space-x-24 mt-5 font-semibold text-xl'>
                                    <div>
                                        Payment Information
                                    </div>
                                </div>
                            </div> */}
                            <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Items Ordered</h1>
                                </div>
                                <div className='mt-5 px-5 w-full'>
                                    <table className='text-center w-full'>
                                        <thead className=' border-b-2 ' >
                                            <th>
                                                Sr. No
                                            </th>
                                            <th >
                                                Product
                                            </th>
                                            <th>
                                                SKU
                                            </th>
                                            {/* <th className='w-32'>
                                                Item Status
                                            </th>
                                            <th className='w-32'>
                                                Original Price
                                            </th> */}
                                            <th className='w-32'>
                                                Price
                                            </th>
                                            <th className='w-32'>
                                                Qty
                                            </th>
                                            <th className='w-32'>
                                                Subtotal
                                            </th>
                                            {/* <th className='w-32'>
                                                Tax Amount
                                            </th>
                                            <th className='w-32'>
                                                Tax Percent
                                            </th>
                                            <th className='w-32'>
                                                Percent Discount
                                            </th> */}
                                        </thead>
                                        <tbody>
                                            {
                                                list.data?.order[0]?.order_details?.map((order, idx) => {
                                                    return (
                                                        <tr className='text-center border-b-2 border-dashed  ' key={idx}>
                                                            <td className='py-3 px-10'>
                                                                {idx + 1}
                                                            </td>
                                                            <td className='py-3 px-10'>
                                                                {order.name}
                                                            </td>
                                                            <td className='py-3 px-10'>
                                                                {order.sku}
                                                            </td>
                                                            {/* <td>
                                                                status
                                                            </td>
                                                            <td>
                                                                original price
                                                            </td> */}
                                                            <td className='py-3'>
                                                                {order.price}
                                                            </td>
                                                            <td className='py-3'>
                                                                {order.qty}
                                                            </td>
                                                            <td className='py-3'>
                                                                {order.price * order.qty}
                                                            </td>
                                                            {/* <td>
                                                                tax amt
                                                            </td>
                                                            <td>
                                                                tax p
                                                            </td>
                                                            <td>
                                                                % discount
                                                            </td> */}
                                                            <td>

                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Prescription</h1>
                                </div>
                                <div className='mt-5 space-y-5'>
                                    {
                                        list?.data?.order[0]?.order_details?.map((lens) => {
                                            return (
                                                <>

                                                    <div className='flex  items-center space-x-5 border-b-2 border-black w-5/6 py-3'>
                                                        <h1 className='font-semibold'>Product Name: </h1>
                                                        <p>{lens?.name || '---'}</p>
                                                    </div>
                                                    <div className='flex  items-center space-x-5'>
                                                        <h1 className='font-semibold'>Power: </h1>
                                                        <p>{power[lens.power] || '---'}</p>
                                                    </div>
                                                    {
                                                        lens?.lens_data?.map((data) => {

                                                            return (
                                                                <>
                                                                    <div className='flex  items-center space-x-5'>
                                                                        <h1 className='font-semibold'>Lens Name: </h1>
                                                                        <p>{data?.lens_name}</p>
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            power[lens?.power] === 'Zero' ? (
                                                                                <></>
                                                                            ) :
                                                                                (
                                                                                    data?.image_url === '' ? (
                                                                                        <>

                                                                                            <div className=' gap-5 grid grid-cols-2'>
                                                                                                <div className='flex  items-center space-x-5'>
                                                                                                    <h1 className='font-semibold'>Distance: </h1>
                                                                                                    <table className='border-2 border-black w-1/2  text-center '>
                                                                                                        <thead>
                                                                                                            <th colSpan={1} className='  border-2 border-black'></th>
                                                                                                            <th className='border-2 px-5 border-black'>Left Eye</th>
                                                                                                            <th className='border-2 px-5 border-black'>Right Eye</th>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td className='border-2  border-black'>Axis</td>
                                                                                                                <td className=' border-2 border-black'>{data.prescription?.distance?.left_eye?.axis || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data.prescription?.distance?.right_eye?.axis || '---'}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Cylinderical</td>
                                                                                                                <td className='border-2 border-black'>{data.prescription?.distance?.left_eye?.cylinderical || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data.prescription?.distance?.right_eye?.cylinderical || '---'}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Spherical</td>
                                                                                                                <td className='border-2 border-black'>{data.prescription?.distance?.left_eye?.spherical || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data.prescription?.distance?.right_eye?.spherical || '---'}</td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <div className='flex  items-center space-x-5'>
                                                                                                    <h1 className='font-semibold'>Reading: </h1>
                                                                                                    <table className='border-2 border-black w-1/2   text-center'>
                                                                                                        <thead>
                                                                                                            <th colSpan={1} className='  border-2 border-black'></th>
                                                                                                            <th className='border-2 px-5 border-black'>Left Eye</th>
                                                                                                            <th className='border-2 px-5 border-black'>Right Eye</th>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Axis</td>
                                                                                                                <td className='border-2 border-black'>{data?.prescription?.reading?.left_eye?.axis || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data?.prescription?.reading?.right_eye?.axis || '---'}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Cylinderical</td>
                                                                                                                <td className='border-2 border-black'>{data?.prescription?.reading?.left_eye?.cylinderical || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data?.prescription?.reading?.right_eye?.cylinderical || '---'}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Spherical</td>
                                                                                                                <td className='border-2 border-black'>{data?.prescription?.reading?.left_eye?.spherical || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data?.prescription?.reading?.right_eye?.spherical || '---'}</td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <div className='flex  items-center space-x-9'>
                                                                                                    <h1 className='font-semibold'>Single: </h1>
                                                                                                    <table className='border-2 border-black  w-1/2 text-center'>
                                                                                                        <thead>
                                                                                                            <th colSpan={1} className='  border-2 border-black'></th>
                                                                                                            <th className='border-2 px-5 border-black'>Left Eye</th>
                                                                                                            <th className='border-2 px-5 border-black'>Right Eye</th>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Axis</td>
                                                                                                                <td className='border-2 border-black'>{data?.prescription?.single?.left_eye?.axis || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data?.prescription?.single?.right_eye?.axis || '---'}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>

                                                                                                                    Cylinderical
                                                                                                                </td>
                                                                                                                <td className='border-2 border-black'>{data?.prescription?.single?.left_eye?.cylinderical || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data?.prescription?.single?.right_eye?.cylinderical || '---'}</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td className='border-2 border-black'>Spherical</td>
                                                                                                                <td className='border-2 border-black'>{data?.prescription?.single?.left_eye?.spherical || '---'}</td>
                                                                                                                <td className='border-2 border-black'> {data?.prescription?.single?.right_eye?.spherical || '---'}</td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className='flex items-center space-x-5'>
                                                                                                <h1 className='font-semibold'>Prescription Image: </h1>
                                                                                                <img src={data?.image_url} alt="" className='w-52 h-40' />
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                )
                                                                        }
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Order Total</h1>
                            </div>
                            <div>
                                <div>
                                    <div className=' w-1/2 flex justify-between py-4 px-4  bg-gray-100'>
                                        <p className='font-semibold'>Subtotal</p>
                                        <p>&#8377; { subTotal}</p>
                                    </div>
                                    <div className=' w-1/2 flex justify-between py-4 px-4'>
                                        {/* <p className='font-semibold'>Shipping & Handling</p>
                                   <p>{'---'}</p> */}
                                    </div>
                                </div>
                                <div>
                                    <div className=' w-1/2 flex justify-between border-t-2 py-4 px-4'>
                                        <p className='font-semibold'>Grand Total</p>
                                        <p> &#8377; {list?.data?.order[0]?.total_sum || '---'}</p>
                                    </div>
                                    {/* <div className=' w-1/2 flex justify-between py-4 px-4'>
                                        <p className='font-semibold'>Total Paid</p>
                                        <p>{'---'}</p>
                                    </div> */}
                                    {/* <div className=' w-1/2 flex justify-between py-4 px-4'>
                                        <p className='font-semibold'>Total Refunded</p>
                                        <p>{'---'}</p>
                                    </div> */}
                                    {/* <div className=' w-1/2 flex justify-between py-4 px-4'>
                                        <p className='font-semibold'>Customer Canceled</p>
                                        <p>{'---'}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default OrderDetails