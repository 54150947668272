import { Modal } from '@mui/material'
import React, { useState } from 'react'
import { GrAdd } from 'react-icons/gr'
import { HiPencil } from 'react-icons/hi'
import EditIdvGeneratedProduct from './EditIdvGeneratedProduct'

const GeneratedProducts = ({ products, name }) => {
  const [isEditIdvModalOpen, setIsEditIdvModalOpen] = useState(false)
  const [editData, setEditData] = useState(null)

  const handleEditIdvProduct = (data) => {
    setEditData(data)
    setIsEditIdvModalOpen(true)
  }

  const handleCloseModal = () => {
    setEditData(null)
    setIsEditIdvModalOpen(false)
  }


  return (
    <>
      <div>
        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Images
                </th>
                <th className="bg-gray-100 w-24 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Name
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  MRP Price
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Selling Price
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Quantity
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Status
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Attributes
                </th>
                {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Action
                </th> */}
              </tr>
            </thead>
            <tbody>

              {products?.map((productData, idx) => {
                const { attributes, mrp_price, selling_price, quantity, images_of_product, status } = productData
                return <tr key={idx}>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm userId">
                    {
                      images_of_product?.length > 0 ? (
                        <div>
                          <div
                            // onClick={(e) => handleOpenUploadModal(e, idx)} 
                            className='flex items-center justify-center h-16 w-16 my-2 border border-dashed border-slate-400 rounded m-auto hover:cursor-pointer'
                          >
                            <img src={images_of_product[0]?.image_url} className='w-full h-full' alt='' />
                          </div>
                          {images_of_product?.length > 1 && <p className=' text-xs mb-2'> + {images_of_product?.length - 1} images</p>}
                        </div>
                      ) : (
                        <div
                          // onClick={(e) => handleOpenUploadModal(e, idx)} 
                          className='flex items-center justify-center h-16 w-16 my-2 border border-dashed border-slate-400 rounded m-auto hover:cursor-pointer'
                        >
                          <GrAdd className='text-slate-400' />
                        </div>
                      )
                    }
                  </td>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm firstName">
                    {name}
                  </td>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm firstName">
                    <input disabled name="mrp_price" value={mrp_price} data-idx={idx} className='w-16 input text-center disabled:border-none bg-white' />
                  </td>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm firstName">
                    <input disabled name="selling_price" value={selling_price} data-idx={idx} className='w-16 input text-center disabled:border-none bg-white' />
                  </td>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm firstName ">
                    <input disabled name="quantity" value={quantity} data-idx={idx} className='w-16 input text-center disabled:border-none bg-white' />
                  </td>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm emailAddress">
                    {parseInt(status) ? "Enabled" : "Disabled"}
                  </td>
                  <td className="border-dashed border-t border-gray-200 text-center text-sm phoneNumber">
                    {attributes.map(attribute => attribute.attribute_value_name).join(', ')}
                  </td>
                  {/* <td className="border-dashed text-center border-t border-gray-200">
                    <HiPencil className='min-h-[1.25rem] min-w-[1.25rem] m-auto hover:cursor-pointer' onClick={() => handleEditIdvProduct(productData)} />
                  </td> */}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
      {
        isEditIdvModalOpen && <EditIdvGeneratedProduct
          open={isEditIdvModalOpen}
          onClose={handleCloseModal}
          editData={editData}
          name={name}
        />
      }

    </>
  )
}

export default GeneratedProducts