import './coupon.css'
import Layout from '../../components/layout'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { getCouponList } from '../../apis/coupon';
import { useQuery } from "@tanstack/react-query";
import Spinner from '../../components/spinner';
import Errors from '../errors';
import AddCoupons from './AddCoupons';
import { useState } from 'react';
import DeleteCoupon from './DeleteCoupon';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import React from "react";
import EditCoupons from './EditCoupons';
import { useTableSearch } from '../../hooks/useTableSearch';
import Pagination from '../../components/elements/Pagination/Pagination';
import { createSearchParams, useSearchParams } from 'react-router-dom';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Coupons() {
    const [searchParams, setSearchParams] = useSearchParams()
    const initialState = {
        limit: 10,
        pageNo: searchParams.get('pageNo') || 0,
        searchQuery:""
    }
    const [openAddModal, setOpenAddModal] = useState(false)
    const [dataCount, setDataCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(parseInt(initialState.pageNo));
    const [couponState, setCouponState] = useState(initialState)
    const handleGetCouponList = useQuery(['couponList', couponState], () => getCouponList(couponState), {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (response) => {
            const { total_count } = response.data
            setDataCount(Math.ceil(total_count / initialState.limit))

        }
    })
    const handleOpenAddCoupon = () => {
        setOpenAddModal(true)
    }
    const handleModalClose = () => {
        setOpenAddModal(false)
        setOpenDeleteModal(false)
        setIsOpenEditModal(false)
    }
    const [couponId, setCouponId] = useState(0)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setCouponId(id)
    }
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const deletedSuccess = () => {
        handleModalClose()
        setIsDeletedSuccess(true)
    }

    const [editData, setEditData] = useState({})
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const handleOpenEditModal = (id) => {
        setIsOpenEditModal(true)
        let data = handleGetCouponList?.data?.data?.data.find(x => x.id === id)
        setEditData(data)
    }

    const [searchVal, setSearchVal] = useState("");
    const { filteredData } = useTableSearch({
        searchVal,
        retrieve: handleGetCouponList?.data?.data?.data
    });
    const handleSearchVal = (e) => {
        let value = e.target.value;
        let data = {
            ...couponState,
            searchQuery: searchVal,
            pageNo: 0,
            [e.target.name]: value
        }
        setCouponState(data);
        setCurrentPage(0);
    }

    const handlePageClick = (val) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        let pageNo = {
            ...couponState,
            pageNo: val.selected
        }
        setCouponState(pageNo)
        setCurrentPage(val.selected)
        setSearchParams(createSearchParams({
            pageNo: val.selected
        }))
    }
    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Coupons</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex items-center space-x-4'>
                            <div className='flex-1'>
                                <input  onChange={handleSearchVal} name='searchQuery' value={couponState.searchQuery} type="text" placeholder='Search Coupons....' className='input' />
                            </div>
                            <div onClick={handleOpenAddCoupon}>
                                <Button variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
                                    Add Coupon
                                </Button>
                            </div>
                        </div>
                        {
                            handleGetCouponList?.data?.data?.data?.length === 0 ? (
                                <>
                                  <h1 className="text-center font-semibold text-2xl">
                No Data Found
              </h1>
                                </>
                            ) : (
                                <>
                                  {handleGetCouponList.isLoading ? <Spinner />
                            : handleGetCouponList.status === "error" ? <Errors errorObject={handleGetCouponList.error} inline /> :
                                <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                                    <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                    Sr.no
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Coupon Name
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Coupon Code
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Discount Percentage/Amount
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Coupon Valid From
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Coupon Valid Till
                                                </th>
                                                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {handleGetCouponList?.data?.data?.data?.map((couponData, idx) => {
                                                const { id, couponName, couponCode, couponDiscountAmount, couponValidFrom, couponValidTill } = couponData
                                                return <tr key={id}>
                                                    <td className="border-dashed border-t border-gray-200 userId">
                                                        <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                                            {couponState.limit * couponState.pageNo + idx + 1}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 firstName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                            {couponName}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                            {couponCode}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                            {couponDiscountAmount}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 lastName">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                            {couponValidFrom}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 emailAddress">
                                                        <span className="text-gray-700 px-6 py-3 block text-center text-sm">
                                                            {couponValidTill}
                                                        </span>
                                                    </td>
                                                    <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                        <div className='flex items-center space-x-5 justify-center'>
                                                            <div>
                                                                <IconButton onClick={() => handleOpenEditModal(id)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                            <div onClick={() => handleOpenDeleteModal(id)}>
                                                                <IconButton>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                        <div className='my-7 flex justify-center items-center'>
                            <Pagination
                                currentPage={currentPage}
                                pageCount={dataCount}
                                onPageChange={handlePageClick}
                                limit={initialState.limit}
                            />

                        </div>
                                </>
                            ) 
                        }
                      
                      
                    </div>
                </div>
            </Layout>
            <AddCoupons open={openAddModal} handleClose={handleModalClose} />
            <EditCoupons open={isOpenEditModal} handleClose={handleModalClose} editData={editData} />
            <DeleteCoupon open={openDeleteModal} onClose={handleModalClose} id={couponId} onDelete={deletedSuccess} />
            <Snackbar open={isDeletedSuccess} autoHideDuration={4000} onClose={() => setIsDeletedSuccess(false)}>
                <Alert onClose={() => setIsDeletedSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Category Deleted Succesfully
                </Alert>
            </Snackbar>
        </>
    )
}

export default Coupons
