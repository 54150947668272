import { FormControlLabel, Switch } from "@mui/material"

function SwitchToggle (props) {
    const {label,onChange,value,checkedValue,name} = props

    return(
        <FormControlLabel
        value={value}
        label={label}
        checked = {checkedValue}
        labelPlacement="start"
        control={<Switch color="primary"  onChange={onChange} name= {name} inputProps={{ 'aria-label': 'controlled' }}/> }
        />
    )
} 
export default SwitchToggle