import { createSearchParams, useSearchParams } from "react-router-dom"
import { useState } from 'react'
import Layout from "../../components/layout"
import useReadUpdated from "../../hooks/updated/useRead"
import Spinner from "../../components/spinner"
import Errors from "../errors"
import Pagination from "../../components/elements/Pagination/Pagination"
import { Button, IconButton } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import EmailModal from "./EmailModal"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteEmail from "./DeleteEmail"
import EditEmail from "./EditEmail"
import { HiFaceSmile } from "react-icons/hi2"
import Moment from "react-moment"

import useCreate from "../../hooks/useCreate"
import toast from "react-hot-toast"

const SpecialCustomers = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(0)
    const [emailModal, setEmailModal] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [customerId, setCustomerId] = useState(0)
    const [editEmailModal, setEditEmailModal] = useState(false)
    const [editData, setEditData] = useState(null)
    const initialData = {
        pageNo: searchParams.get('pageNo') || 0,
        limit: 10,
        searchQuery: ''
    }
    const { list, paramsObject, setGetListParams } = useReadUpdated({
        url: "special_user_list",
        method: "POST",
        initialData: initialData,
        onSuccess: () => {

        }
    })
    const handleModalClose = () => {
        setOpenDeleteModal(false)
    }

    const {setDataToServer} = useCreate({
        url:"upload_email_excel",
        onSuccess:() => {
            toast.success("File Uploaded Successfully",{
                position:'top-right',
                duration:3000
            })
        },
        onError:() => {
            toast.error("Oops! Something went Wrong",{
                position:'top-right',
                duration:3000
            })
        }
    })

    const onFileChange = (e) => {
        let excelSheet = {
            file:e.target.files[0]
        }
        setDataToServer.mutate(excelSheet)
    }

    console.log(list?.data)
    // const emailList = [
    //     {
    //         email: "abhinavgvalani8@gmail.com"
    //     },
    //     {
    //         email: "asgvalani@gmail.com"
    //     },
    //     {
    //         email: "abhinavgvalani@gmail.com"
    //     },
    //     {
    //         email: "abhinav@gmail.com"
    //     },
    //     {
    //         email: 'skrillexssb@gmail.com'
    //     },
    //     {
    //         email: "i@gmail.com"
    //     },
    //     {
    //         email: "tonystark@gmail.com"
    //     },
    //     {
    //         email: "qwerty@gmail.com"
    //     },
    //     {
    //         email: "abc@gmail.com"
    //     },
    //     {
    //         email: " user@user.com"
    //     }
    // ]
    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            searchQuery: searchVal,
            pageNo: 0,
            [e.target.name]: value
        }
        setGetListParams(data)
        setCurrentPage(0)
        // setSearchVal(data)
    }
    const handlePageClick = (val) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let pageNo = {
            ...paramsObject,
            pageNo: val.selected,
        };
        setGetListParams(pageNo);
        setCurrentPage(val.selected);
        setSearchParams(
            createSearchParams({
                pageNo: val.selected,
            })
        );
    };
    const handleOpenEmailModal = () => {
        setEmailModal(true)
    }
    const handleCloseEmailModal = () => {
        setEmailModal(false)
    }
    const handleOpenDeleteModal = (id) => {
        setOpenDeleteModal(true)
        setCustomerId(id)
        console.log(id)

    }
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false)
    }

    const handleOpenEditModal = (data) => {
        setEditEmailModal(true)
        setEditData({ ...data })

    }
    const handleCloseEditModal = () => {
        setEditEmailModal(false)
        setEditData(null)
    }
    const totalCount = list?.data?.total_count
    return (
        <>
            <Layout>
                <div className="category">
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Special Customers</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex items-center space-x-4'>
                            <div className='flex-1'>
                                <input onChange={handleSearchVal} name='searchQuery' value={paramsObject?.searchQuery} type="text" placeholder='Search Email....' className='input' />
                            </div>
                            <div>

                                <Button onClick={handleOpenEmailModal} variant="outlined" startIcon={<AddIcon />} className='primaryBtn-outlined'>
                                    Email
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="w-full flex justify-end">
                <label for="btn" className="bg-[color:var(--color3)] text-white rounded px-4 py-4 mb-2  font-medium cursor-pointer">
                        <input type="file" name="file" id="btn" accept=".xlsx, .xls" hidden onChange={onFileChange} />
                        Upload xlsx
                    </label>
                </div> */}
                {
                    list?.data?.data?.length === 0 ? (
                        <h1 className="text-center font-semibold text-2xl">
                            No Data Found
                        </h1>
                    ) : (
                        <>
                            {
                                list.isLoading ? (
                                    <Spinner />
                                ) : list.status === 'error' ? (
                                    <Errors errorObject={list.error} inline />
                                ) :
                                    (
                                        <>

                                            <div className="overflow-x-auto mt-5 bg-white rounded-lg shadow overflow-y-auto relative">
                                                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                                                    <thead>
                                                        <tr className="text-left">
                                                            <th className="bg-gray-100 text-center sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Sr.no
                                                            </th>
                                                            <th className="bg-gray-100 text-center sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Email
                                                            </th>
                                                            <th className="bg-gray-100 text-center sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Created On
                                                            </th>
                                                            {/* <th className="bg-gray-100 text-center sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                                                                Updated On
                                                            </th> */}
                                                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                                                                Action
                                                            </th>
                                                        </tr>

                                                    </thead>
                                                    <tbody>
                                                        {list?.data?.data.map((specialData, idx) => {
                                                            const {
                                                                id,
                                                                email,
                                                                created_on,
                                                                updated_on

                                                            } = specialData;
                                                            return (
                                                                <>
                                                                    <tr className="text-center" key={idx}>
                                                                        <td className="border-dashed border-t border-gray-200 userId">
                                                                            <span className="text-gray-700 px-6 py-3 flex justify-center items-center text-sm">
                                                                                {/* {(orderState.limit * (orderState.pageNo + 1)) - (orderState.limit - (idx + 1))} */}

                                                                                {initialData.limit * initialData.pageNo + idx + 1}
                                                                                {/* {
                                                                                idx + 1
                                                                            } */}
                                                                            </span>
                                                                        </td>
                                                                        <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                                {email || '---'}
                                                                            </span>
                                                                        </td>
                                                                        <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                                <Moment date={created_on} unix format="llll" />
                                                                            </span>
                                                                        </td>
                                                                        {/* <td className="border-dashed border-t border-gray-200 text-center lastName">
                                                                            <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                                                                <Moment date={updated_on} unix format="llll" />
                                                                            </span>
                                                                        </td> */}
                                                                        <td className="border-dashed border-t border-gray-200 phoneNumber">
                                                                            <div className='flex items-center space-x-5 justify-center'>
                                                                                                                        
                                                                                    <IconButton onClick={() => handleOpenDeleteModal(id)}>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </>

                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className=" my-7 flex justify-center items-center">
                                                <Pagination
                                                    currentPage={currentPage}
                                                    pageCount={Math.ceil(totalCount / initialData.limit)}
                                                    onPageChange={handlePageClick}
                                                    limit={initialData.limit}
                                                />
                                            </div>
                                        </>
                                    )
                            }
                        </>
                    )
                }
            </Layout>
            <EmailModal open={emailModal} onClose={handleCloseEmailModal} />
            <DeleteEmail open={openDeleteModal} onClose={handleCloseDeleteModal} id={customerId} onDelete={handleModalClose} />
            {
                editEmailModal &&

                <EditEmail open={editEmailModal} onClose={handleCloseEditModal} editData={editData} />
            }
        </>
    )

}

export default SpecialCustomers