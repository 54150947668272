import React, { useState } from 'react'
import { Autocomplete, Button, Chip, Dialog, IconButton, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import useReadUpdated from '../../hooks/updated/useRead';
import useCreate from '../../hooks/useCreate';
import Modal from '@mui/material/Modal';
import toast from 'react-hot-toast';
import Spinner from '../../components/spinner';
const EmailModal = ({ open, onClose }) => {
  const regex = /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/;
  const [selected, setSelected] = useState([])
  const [options,setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [error, setError] = useState(false)
  const {list} = useReadUpdated({
      url:'special_user_list',
      method:'POST',
      onSuccess:(data) => {
        setOptions(data?.data)
        console.log(data?.data)
      }
  })
  const {setDataToServer} = useCreate({
      url:'add_special_users',
      refreshUrl:"special_user_list",
      onSuccess:(data) => {
        setSelected([])
        toast.success("Email Added Successfully",{
          duration:3000,
          position:'top-right'
        })
        onClose()
      //   setTimeout(() => {
      //     window.location.reload()
      // },[2000])
      },
  
  })
  const onChange = (e, value) => {
    const errorEmail = value.find((email) => !regex.test(email));
    if (errorEmail) {
      setQuery(errorEmail);
      setError('Please enter a valid email address');
    } else {
      setError(false);
    }
    console.log('value', value)
    setSelected(value.filter((email) => regex.test(email)));
  }
  const deleteEmail = (value) => {
    setSelected(selected.filter((email) => email !== value))
  }

  const onInputChange = (e, val) => {
    setQuery(val)
  }
  const onSubmit = (e) => {
  e.preventDefault()
    console.log('form Submitted')
    console.log('selected', selected)
    // if(selected.length === 0 || error) {
    //  return  setError("Please Enter At least one Email")
    // }
    setError(null)
    const data={
      email:selected.map((label)=>label)
    }
    if(selected.length > 0)
    {
      setDataToServer.mutate(data)
    }
    else {
      setError('Please enter at least one email address')
    }
  
    console.log(data)
  }
  return (
    <>
      <Modal fullWidth open={open}>

        <div className='w-full absolute top-4 left-1/2 -translate-x-1/2 focus:outline-none'>
          <div className=' max-w-xl mx-auto bg-white'>
          <div className="head-modal">
              <div className='flex justify-end items-center space-x-4 px-6'>
                <div className='mt-5'>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                {/* <h2 className='heading text-3xl'>Edit Email</h2> */}
              </div>
            </div>
            <div className='py-4 px-6 h-max'>
              <form onSubmit={onSubmit}>
                <div className='flex justify-center items-center space-x-5 w-full mt-5'>
                  <label>Email: </label>
                  <Autocomplete
                    className='w-full border-none'
                    multiple
                    onChange={onChange}
                    value={selected.map((label)=>label)}
                    inputValue={query}
                    freeSolo
                    onInputChange={onInputChange}
                    options={options.map((label)=>label?.email)}
                    renderTags={(value, getTagProps) =>
                      value.map((opt, index) => {
                        console.log('opt',opt)
                        return <Chip
                          variant='outlined'
                          label= {opt}
                          {...getTagProps({ index })}
                          onDelete={() => deleteEmail(opt)}
                        />
                      })
                    }
                    renderInput={(params) =>
                    (
                      <TextField
                        {...params}
                        className='input'
                        placeholder='Enter Email'
                        type="email"
                        error={error}
                        helperText={error}
                      />
                    )
                    }
                  />


                </div>
                <div className=' space-x-4 text-center pb-5 mt-5'>
                  {setDataToServer.isLoading ?
                    <Spinner /> :
                    <>
                      <Button  type='submit' variant="contained">Save</Button>
                      <Button variant="outlined" onClick={onClose}>Close</Button>
                    </>
                     } 
                </div>
              </form>
            </div>
          </div>

        </div>
      </Modal>

    </>
  )
}

export default EmailModal