import { Button, Dialog, IconButton } from '@mui/material'
import React from 'react'
import useCreate from '../../hooks/useCreate'
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';
import Spinner from '../../components/spinner';
const DeleteEmail = ({ open, onClose, id,onDelete }) => {
    const {setDataToServer} = useCreate({
        url:' delete_special_users',
        refreshUrl:"special_user_list",
        onSuccess: () => {
            
            toast.success("User Deleted Successfully",{
                position:'top-right',
                duration:3000
            })
            // setTimeout(() => {
            //     window.reload()
            // },[1000])
            onDelete()
        }
        

    })
    const handleDeleteEmail = (e) => {
        e.preventDefault()
        setDataToServer.mutate({
            id:id
        })
        onClose()

    }
    return (
        <>
            <Dialog fullWidth maxWidth = {"sm"} open={open} >
                <div className='bg-white w-full px-5 p-5'>
                    {/* <div className='flex w-full justify-end'>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div> */}
                    <div>
                <form className=' space-y-4 mt-5'  onSubmit={handleDeleteEmail}>
                        <div className=' space-y-7'>
                            <div>
                                <h1 className=' text-2xl text-red-700 font-semibold text-center'>Are You Sure You Want To Delete?</h1>
                            </div>
                        </div>
                        {
                            setDataToServer.isLoading ? <>
                            <Spinner/>
                            </> :
                            <>
                              <div className=' space-x-4 text-center pb-5'>
                            <Button type='submit' variant="contained" color={`error`}>Delete</Button>
                            <Button variant="outlined" color='info' onClick={onClose}>Close</Button>
                        </div>
                            </>
                        }
                      
                    </form>
                </div>
                </div>
                
            </Dialog>
        </>
    )
}

export default DeleteEmail