import React from 'react'
import Slide from '@mui/material/Slide'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Dialog, IconButton } from '@mui/material'
import useReadUpdated from '../../hooks/updated/useRead'
import { useParams } from 'react-router-dom'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const BulkOrderDetails = ({ open, onClose, data }) => {
    // const { id } = useParams()
    // const { bulkOrderList, paramsObject, setGetListParams } = useReadUpdated({
    //     method: "POST",
    //     url: "bulk_order_details",
    //     initialData: {
    //         id: id
    //     }
    // })
    // console.log('data', data)
    return (
        <Dialog fullScreen maxWidth={'md'} open={open} onClose={onClose} TransitionComponent={Transition}>
            <div className='container_xxl'>
                <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                    <div>
                        <IconButton onClick={onClose}>
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h1 className='heading '>Bulk Order Details</h1>
                    </div>
                </div>
                <div className='form-body py-8'>


                    <div className='space-y-10'>
                        <div>
                            <div className='flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Order & Account Information</h1>
                            </div>
                            <div className='flex justify-evenly mt-5 w-full space-x-10 '>
                                <div className='w-full space-y-5'>

                                    <div className='font-semibold text-xl'>
                                        Order
                                    </div>
                                    <div>

                                        <div className='w-full flex justify-between py-4 px-4 bg-gray-100 '>
                                            <p className='font-semibold'>Enquiry Date</p>
                                            {
                                                data?.enquiry_date
                                            }
                                        </div>
                                        <div className='w-full flex justify-between py-4 px-4 bg-white'>
                                            <p className='font-semibold'>Enquiry Status</p>
                                            {
                                                data?.process_status
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='space-y-5 w-full'>
                                    <div className='font-semibold text-xl'>
                                        Account Information
                                    </div>
                                    <div>
                                        <div className=' w-full flex justify-between py-4 px-4 bg-gray-100'>
                                            <p className='font-semibold'> Name</p>
                                            <p>{data?.first_name}</p>
                                        </div>
                                        <div className=' w-full flex justify-between py-4 px-4'>
                                            <p className='font-semibold'>Email</p>
                                            <p>{data?.email}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                                <div className='flex items-center justify-between border-b border-gray-400'>
                                    <h1 className='font-semibold text-2xl pb-3'>Address Information</h1>
                                </div>
                                <div className='flex space-x-24 mt-5 font-semibold text-xl'>
                                    <div>
                                        Billing Address
                                    </div>
                                    <div>
                                        Shipping Address
                                    </div>
                                </div>
                            </div> */}
                        <div>
                            <div className='flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Address Information</h1>
                            </div>
                            <div className='flex justify-evenly mt-5 w-full space-x-10 '>
                                <div className='w-full space-y-5'>

                                    <div className='font-semibold text-xl'>
                                        Address
                                    </div>
                                    <div>
                                        <p className='font-bold'>{data?.first_name}</p>
                                        <p>{data?.address}</p>
                                        <p>Phone No. : {data?.mobile_no}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div>
                            <div className='flex items-center justify-between border-b border-gray-400'>
                                <h1 className='font-semibold text-2xl pb-3'>Enquiry Images</h1>
                            </div>
                            <div className='flex space-x-24 mt-5 font-semibold text-xl'>
                                <div>
                                    Images
                                </div>
                            </div>
                            <div className='grid grid-cols-3 gap-x-5'>
                                {
                                    data?.images?.length === 0 ? <>
                                        <h1 className="text-center font-semibold text-2xl">
                                            No Images Found
                                        </h1>
                                    </> :
                                        data?.images?.map((image, idx) => {
                                            const { image_path } = image
                                            return (
                                                <p className='mt-5 ' key={idx}><img className=' w-full' src={image_path} alt='' /></p>
                                            )
                                        })
                                }
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Dialog>
    )
}

export default BulkOrderDetails