import { Alert, Snackbar } from '@mui/material';

export const SuccessAlert = ({ visible, onClose, text }) => {
  return (
    <Snackbar open={visible} autoHideDuration={6000} onClose={onClose}>
      <Alert variant='filled' onClose={onClose} severity="success" sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  )
}