import { createSearchParams, useSearchParams } from "react-router-dom";
import Layout from "../../components/layout";
import useReadUpdated from "../../hooks/updated/useRead";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import spinner from '../../assets/images/common/spinner.gif'
import { useQuery } from "@tanstack/react-query";
import Spinner from '../../components/spinner';
import { useState } from 'react';
import Errors from "../errors";
import Pagination from "../../components/elements/Pagination/Pagination";
import BulkOrderDetails from "./BulkOrderDetails";
import useCreate from "../../hooks/useCreate";
const BulkOrders = () => {
    const [searchParams,setSearchParams] = useSearchParams()

    const initialState = {
        limit: 10,
        pageNo: searchParams.get('pageNo') || 0,
        searchQuery: '',
        statusFilter:1
    }
    const {list:bulkOrderList,paramsObject,setGetListParams} = useReadUpdated({
        method:"POST",
        url:"bulk_order_details",
        initialData:initialState,
        onSuccess : (data) => {
            console.log(data)
            setCurrentPage(data?.page_no)
        }
      })
    const {setDataToServer} = useCreate({
        url:'update_status',
        onSuccess: () => {
            bulkOrderList.refetch()
        }
    })
      const [currentPage,setCurrentPage] = useState(parseInt(initialState.pageNo))
      const [searchVal,setSearchVal] = useState('')
      const [openBulkOrderModal,setBulkOpenOrderModal] = useState(false)
      const [orderId,setOrderId] = useState(0)
      const [currentEnquiry,setCurrentEnquiry] = useState({})
      const [filterStatus,setFilterStatus] = useState(initialState.statusFilter)
      const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
            ...paramsObject,
            searchQuery:searchVal,
            pageNo: 0,
            [e.target.name]: value
        }
        setGetListParams(data)
        setCurrentPage(0)
        // setSearchVal(data)
    }
    const handleChangeStatus = (e,process_id, id) => {
        const newStatus = { statusFilter:e.target.value,process_id, id };
        setDataToServer.mutate(newStatus);
        console.log(newStatus)
    
      };
    
      const handleFilterStatus = (e) => {
        let data = {
          ...paramsObject,
          statusFilter: e.target.value,
        };
        setFilterStatus(e.target.value);
        setGetListParams(data);
      };

      const handlePageClick = (val) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        let pageNo = {
          ...paramsObject,
          pageNo: val.selected,
        };
        setGetListParams(pageNo);
        setCurrentPage(val.selected);
        setSearchParams(
          createSearchParams({
            pageNo: val.selected,
          })
        );
      };
      
  const handleOpenOrderModal = (e,id,data) => {
    setCurrentEnquiry(data)
    setOrderId(id)
    setBulkOpenOrderModal(true)
    
  }

  const handleCloseOrderModal = () =>{
    setBulkOpenOrderModal(false)
    
  }
      console.log(bulkOrderList?.data?.data)
      const statusList = [
        {
            o_id:0,
            process_status:'Failed'
        },
        {
            o_id:1,
            process_status:'Process'
        },
        {
            o_id:2,
            process_status:'Under Process'
        },
        {
            o_id:3,
            process_status:'Cancelled'
        },
      ]
      const statusColors = {
        0: "bg-red-100 text-red-600",
        1: "bg-green-100 text-green-600",
        2: "bg-yellow-100 text-yellow-600",
        3: "bg-red-100 text-red-600",
      };
      const totalCount = bulkOrderList?.data?.total_count
    return (
       <>
        <Layout>
        {setDataToServer.isLoading && <div className="fixed w-full h-full  z-50 top-0 left-0 bg-[#ffffff7d]"> <img src={spinner} alt="" className="w-[10rem] h-[10rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /></div>}
        <div className="category">
          <div className="category__head headingBorder__b">
            <h2 className="titleHeading">Bulk Orders</h2>
          </div>
          <div className="category_table py-5 space-y-5">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  onChange={handleSearchVal}
                //   value={orderState.searchQuery}
                  name="searchQuery"
                  type="text"
                  placeholder="Search Order By Customer Number...."
                  className="input"
                />
              </div>
            </div>
            <div className="w-full flex justify-end items-center px-10 space-x-5">
              <h1>Filter: </h1>
              <div>
                <select
                  value={filterStatus}
                  onChange={(e) => handleFilterStatus(e)}
                  className={
                    `px-4 py-1 rounded capitalize text-sm bg-red-100 text-red-600 ` +
                    statusColors[filterStatus]
                  }
                >
                  {
                    statusList?.map((status)=>

                    {
                        const {o_id,process_status} = status
                          return(
                                  <>
                                  <option value={o_id} className={statusColors[o_id]}>{process_status}</option>
                                  </>
                                )
                    })
                  }
                </select>
              </div>
            </div>
            {bulkOrderList?.data?.data?.length === 0 ? (
              <h1 className="text-center font-semibold text-2xl">
                No Data Found
              </h1>
            ) : (
              <div>
                {bulkOrderList.isLoading ? (
                  <Spinner />
                ) : bulkOrderList.status === "error" ? (
                  <Errors errorObject={bulkOrderList.error} inline />
                ) : (
                  <>
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                      <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                          <tr className="text-left">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                              Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Name
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Email
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Phone Number
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Enquiry Date
                            </th>
                            {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Total Sum
                            </th> */}
                            {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Reference No
                            </th> */}
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Status
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              View
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulkOrderList?.data?.data?.map((orderData, idx) => {
                            const {
                            id,
                              first_name,
                              email,
                              mobile_no,
                              enquiry_date,
                              process_status,
                             process_id
                            } = orderData;
                            return (
                              <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                    {/* {(orderState.limit * (orderState.pageNo + 1)) - (orderState.limit - (idx + 1))}
                                     */}
                                    {initialState.limit * initialState.pageNo + idx + 1}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center firstName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    {first_name}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center lastName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                   {email}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center lastName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    {mobile_no}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center lastName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    {enquiry_date}
                                  </span>
                                </td>
                               
                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                <div className='flex items-center justify-center'>
                                  <select value={process_id} onChange={(e) => handleChangeStatus(e,process_id,id)} className={`px-4 py-1 rounded capitalize text-sm ` + statusColors[process_id]}>
                                    {
                                      statusList?.map((status) => {
                                        console.log(status)
                                        const { o_id, process_status } = status
                                        return (
                                          <>
                                            <option value={o_id} className={statusColors[o_id]}>{process_status}</option>
                                          </>
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                                
                                </td>
                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                  <div className="flex items-center space-x-5 justify-center">
                                    <div
                                      onClick={(e)=> handleOpenOrderModal(e,id,orderData)}
                                    >
                                      <IconButton>
                                        <RemoveRedEyeIcon />
                                      </IconButton>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className=" my-7 flex justify-center items-center">
                      <Pagination
                        currentPage={currentPage}
                        pageCount={Math.ceil(totalCount/initialState.limit)}
                        onPageChange={handlePageClick}
                        limit={initialState.limit}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Layout>
      {
        openBulkOrderModal && <BulkOrderDetails open={openBulkOrderModal} onClose={handleCloseOrderModal} data = {currentEnquiry} />
      }
       </>
    )
}

export default BulkOrders