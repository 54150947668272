import React from 'react'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactSortable } from 'react-sortablejs';

const ProductDetails = ({ productData, setProductData }) => {

  const handleAddProductDetail = () => {
    const tempArr = productData?.product_details
    tempArr.push({
      title: "",
      content: [
        ""
      ]
    })
    setProductData({
      ...productData,
      product_details: tempArr
    })
  }

  const handleAddProductDescription = (idx) => {
    const tempArr = productData?.product_details
    tempArr[idx].content.push("")
    setProductData({
      ...productData,
      product_details: tempArr
    })
  }

  const handleProductDetailTitleChange = (e, idx) => {
    const val = e.target.value
    const tempArr = productData?.product_details
    tempArr[idx].title = val
    setProductData({
      ...productData,
      product_details: tempArr
    })
  }

  const handleProductDetailDescriptionChange = (e, idx, cIdx) => {
    const val = e.target.value
    const tempArr = productData?.product_details
    tempArr[idx].content[cIdx] = val
    setProductData({
      ...productData,
      product_details: tempArr
    })
  }

  const handleDeleteProductDetail = (idx) => {
    const tempArr = productData?.product_details
    tempArr.splice(idx, 1)
    setProductData({
      ...productData,
      product_details: tempArr
    })
  }

  const handleDeleteProductDetailDescription = (idx, cIdx) => {
    const tempArr = productData?.product_details[idx]?.content
    tempArr.splice(cIdx, 1)
    if (tempArr?.length < 1) {
      handleDeleteProductDetail(idx)
    }
    const tempProductDetails = productData.product_details
    setProductData({
      ...productData,
      product_details: tempProductDetails
    })
  }
  const setProductDataDetails = (val) => {
    const newValue = {...productData}
    newValue.product_details = val
    setProductData(newValue)
  }


  return (
    <div>
      <div className='my-7 flex items-center justify-between border-b border-gray-400'>
        <h1 className='font-semibold text-2xl pb-3'>Product Details</h1>
        <div>
          <button onClick={handleAddProductDetail} type='button' className='border-gray-800 bg-gray-800 text-white px-6 py-2 rounded mb-2'>
            <span>Add Detail</span>
          </button>
        </div>
      </div>
      <div>
      <ReactSortable list={productData?.product_details} setList={setProductDataDetails}>
      {
          productData?.product_details?.map((item, idx) => {
            return (
              <div key={idx} className='border rounded p-4 border-gray-500 shadow-lg mt-4'>
                <div>
                  <div className='flex items-center justify-between'>
                    <label className='label'>Title</label>
                    <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteProductDetail(idx)}>
                      <IconButton color="error" aria-label="add an delete" size="large">
                        <DeleteIcon />
                      </IconButton>
                    </div>

                  </div>
                  <input type="text" placeholder='Detail Title' name='title' className='input' value={productData?.product_details[idx]?.title} onChange={(e) => handleProductDetailTitleChange(e, idx)} />
                </div>
                <div className='mt-4'>
                  <label className='label'>Description</label>
                  {
                    item?.content?.map((it, cIdx) => {
                      return (
                        <div key={cIdx} className='flex items-center mb-2'>
                          <input key={cIdx} type="text" placeholder='Description' name='title' className='input' value={productData?.product_details[idx]?.content[cIdx]} onChange={(e) => handleProductDetailDescriptionChange(e, idx, cIdx)} />
                          <div className='px-2 h-full flex items-center justify-center' onClick={() => handleDeleteProductDetailDescription(idx, cIdx)}>
                            <IconButton color="error" aria-label="add an delete" size="small">
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

                <button onClick={() => handleAddProductDescription(idx)} type='button' className='border-gray-800 bg-gray-800 text-white px-6 py-2 rounded mb-2 w-full mt-2'>
                  <span>Add Description</span>
                </button>

              </div>
            )
          })
        }
      </ReactSortable>
      </div>
    </div>
  )
}

export default ProductDetails