import React, { useRef, useState } from 'react'
import SummaryImage from './SummaryImage'
import SummaryPrice from './SummaryPrice'
import SummaryQuantity from './SummaryQuantity'
import { Button, Modal } from '@mui/material'
import { GrAdd } from 'react-icons/gr'
import useConfigImageUpload from '../../../../hooks/useConfigImageUpload'
import useReadUpdated from '../../../../hooks/updated/useRead'

const IMAGE = "IMAGE"
const MRP_PRICE = "MRP_PRICE"
const SELLING_PRICE = "SELLING_PRICE"
const QUANTITY = "QUANTITY"

const TABS = [
  {
    name: "Product Image",
    actionValue: IMAGE
  },
  {
    name: "Product MRP Price",
    actionValue: MRP_PRICE
  },
  {
    name: "Product Selling Price",
    actionValue: SELLING_PRICE
  },
  {
    name: "Product Quantity",
    actionValue: QUANTITY
  }
]


const Tab = ({ selectedTab, setTab, tab }) => {
  const { name, actionValue } = tab
  return <p className={`${selectedTab === actionValue ? 'text-red-500 bg-red-50 border-red-500' : 'text-gray-400'} rounded px-3 py-2 hover:cursor-pointer font-semibold text-sm`} onClick={() => setTab(actionValue)}>{name}</p>
}

const Summary = ({ products, setProducts, editData, configData }) => {
  const [tab, setTab] = useState(IMAGE)
  const [productImageEditIdx, setProductImageEditIdx] = useState(0)
  const productImageUpload = useRef(null)

  const { setDataToServer: uploadImage } = useConfigImageUpload({
    refreshUrl: '',
    url: "configUploadImage",
    onSuccess: (data) => {
      setProducts(data?.data)
    }
  })
  // const {} = useReadUpdated({
  //   url:''
  // })

  const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
  const handleOpenUploadModal = (e, idx) => {
    setProductImageEditIdx(idx)
    setOpenUploadImagesModal(true)
  }
  const handleCloseUploadImageModal = () => {
    setOpenUploadImagesModal(false)
  }
  const handleClickOpenFileDialog = () => {
    productImageUpload.current.click()
  }
  const handleUploadImages = (e) => {
    uploadImage.mutate({
      product_image: e.target.files[0]
    }, {
      onSuccess: ({ data }) => {
        const mediaId = data.mediaId
      
        let previewImag = []
        for (let i = 0; i < e.target.files.length; i++) {
          previewImag.push(data?.path)
        }
        let temp = [...products]
        temp[productImageEditIdx].images_of_product = [...temp[productImageEditIdx].images_of_product, mediaId]
        temp[productImageEditIdx].images_of_product_preview = [...temp[productImageEditIdx]?.images_of_product_preview, ...previewImag]

        setProducts(temp)
      }
    })

  }

  const handleRemoveSelectedProductImage = (e, id) => {
    e.stopPropagation();
    let temp = [...products]
    temp[productImageEditIdx].images_of_product_preview.splice(id, 1)
    temp[productImageEditIdx].images_of_product.splice(id, 1)
    setProducts(temp)
  }

  const handleInputChange = (e) => {
    const name = e.target.name
    const val = e.target.value
    const idx = e.target.dataset.idx
    let temp = [...products]
    temp[idx][name] = val
    setProducts(temp)
  }
  

  return (
    <>
      <div className=' space-y-6 mb-16'>
        <div className=''>
          <h1 className='text-gray-700 font-semibold text-3xl'>Step 3: Summary</h1>
        </div>
        
       
            <div className='border flex w-max rounded'>
          {
            TABS.map((it) => {
              return <Tab selectedTab={tab} setTab={setTab} tab={it} />
            })
          }
        </div>
        {tab === IMAGE && <SummaryImage products={products} setProducts={setProducts} />}
        {tab === MRP_PRICE && <SummaryPrice products={products} setProducts={setProducts} name={'mrp_price'} />}
        {tab === SELLING_PRICE && <SummaryPrice products={products} setProducts={setProducts} name={'selling_price'} />}
        {tab === QUANTITY && <SummaryQuantity products={products} setProducts={setProducts} />}
        <div>
          <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
            <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
              <thead>
                <tr className="text-left">
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Images
                  </th>
                  <th className="bg-gray-100 w-24 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Name
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    MRP Price
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Selling Price
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Quantity
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Status
                  </th>
                  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                    Attributes
                  </th>
                </tr>
              </thead>
              <tbody>
                {products?.map((productData, idx) => {
                  const { attributes, mrp_price, selling_price, quantity, images_of_product_preview, status } = productData
                  console.log(images_of_product_preview)
                  return <tr key={idx}>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm userId">

                      {
                        images_of_product_preview.length > 0 ? (
                          <div>
                            <div onClick={(e) => handleOpenUploadModal(e, idx)} className='flex items-center justify-center h-16 w-16 my-2 border border-dashed border-slate-400 rounded m-auto hover:cursor-pointer'>
                              <img src={images_of_product_preview[0]} className='w-full h-full' alt='product preview ' />
                            </div>
                            {images_of_product_preview.length > 1 && <p className=' text-xs mb-2'> + {images_of_product_preview.length - 1} images</p>}
                          </div>
                        ) : (
                          <div onClick={(e) => handleOpenUploadModal(e, idx)} className='flex items-center justify-center h-16 w-16 my-2 border border-dashed border-slate-400 rounded m-auto hover:cursor-pointer'>
                            <GrAdd className='text-slate-400' />
                          </div>
                        )
                      }
                    </td>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm firstName">
                      {editData?.product_name} {attributes?.map((attribute)=> attribute.attribute_value_name)?.join(' ')}
                    </td>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm firstName">
                      <input name="mrp_price" value={mrp_price} data-idx={idx} onChange={handleInputChange} className='w-16 input text-center' />
                    </td>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm firstName">
                      <input name="selling_price" value={selling_price} data-idx={idx} onChange={handleInputChange} className='w-16 input text-center' />
                    </td>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm firstName ">
                      <input name="quantity" value={quantity} data-idx={idx} onChange={handleInputChange} className='w-16 input text-center' />
                    </td>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm emailAddress">
                      {parseInt(status) ? "Enabled" : "Disabled"}
                    </td>
                    <td className="border-dashed border-t border-gray-200 text-center text-sm phoneNumber">
                      {attributes.map(attribute => attribute.attribute_value_name).join(', ')}
                    </td>
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        </div>
          
      </div>

      <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
          <div className=' max-w-5xl mx-auto bg-white'>
            <div className=' divide-y'>
              <div className="head-modal">
                <div className='flex items-center space-x-4 px-6'>
                  <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                    <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                  </div>
                  <h2 className='heading text-3xl'>Upload Product Images</h2>
                </div>
              </div>
              <div className="body-modal py-4 px-6">
                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative cursor-pointer'>
                  <div className='imagePreview__container relative z-10'>
                    <div className="grid grid-cols-4 gap-4 ">
                      {products[productImageEditIdx]?.images_of_product_preview?.map((img, idx) => {
                       console.log(products[productImageEditIdx]?.images_of_product_preview)
                        return <div className=' w-full h-48 overflow-hidden border p-2 relative cursor-default' key={idx}>
                          <div className='w-6 h-6 cursor-pointer float-right' onClick={(e) => handleRemoveSelectedProductImage(e, idx)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <img src={img} alt="img" className='w-full h-full object-contain' />
                        </div>
                      })}
                      <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                        <GrAdd className=' w-16 h-16 text-gray-400' />
                        <small>Add Product Image</small>
                      </div>
                    </div>
                  </div>
                  {products[productImageEditIdx].images_of_product_preview.length <= 0 && <span className='text-gray-400 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Click here to Upload</span>}
                </div>
                <input type="file" className='hidden' ref={productImageUpload} onChange={handleUploadImages} multiple accept="image/jpeg, image/png, image/jpg" />
              </div>
              <div className="foot-modal py-4 text-center">
                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Summary