import React from 'react'
import Layout from '../../components/layout'
import { MdAdd } from 'react-icons/md';
import { Alert, Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import useReadUpdated from '../../hooks/updated/useRead';
import useCreate from '../../hooks/useCreate';
import { SuccessAlert } from '../../components/alerts/Alerts';
import { toast } from 'react-hot-toast';


const Taxes = () => {
    let initialState = {
        pageNo: 0,
        limit: 20,
        searchQuery: ''
    }
    const [isDeletedSuccess, setIsDeletedSuccess] = useState(false)
    const [isCreatedSuccess, setIsCreatedSuccess] = useState(false)
    const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false)
    const [taxData, setTaxData] = useState([])
    const [searchVal,setSearchVal] = useState('')
    const handleAddTaxField = () => {
        let newField = {
            name: '',
            value: '',
        }
        setTaxData(prevData => [newField, ...prevData,])
    }

    const { list,paramsObject,setGetListParams } = useReadUpdated({
        url: 'gettaxList',
        initialData: initialState,
        onSuccess: (data) => {
            setTaxData(data.data)
        }
    })

    const total_count = list?.data?.count

    const handlePageClick = (val) => {
        let data = {
            ...paramsObject,
            pageNo:val.selected
        }
        setGetListParams(data)
    }

    const handleSearchVal = (e) => {
        let value = e.target.value
        let data = {
          ...paramsObject,
          searchQuery:searchVal,
          [e.target.name]: value
        }
        setGetListParams(data)
      }
    

    const handleChangeInput = (e, idx) => {
        let copyData = taxData
        let $this = e.currentTarget
        copyData[idx][$this.name] = $this.value
        setTaxData([...taxData])
    }
    const handleDeleteTaxField = (fieldID) => {
        const temp = taxData[fieldID]
        setDeleteTaxToServer.mutate(temp)
    }

    const { setDataToServer: setCreateTaxToServer } = useCreate({
        refreshUrl: 'getaxList',
        url: 'createtax',
        onSuccess: () => {
            setIsCreatedSuccess(true)
            toast.success("Tax Created Successfully",{
                position:"top-right",
                duration:3000
            })
        },
        onError: () => { }
    })

    const { setDataToServer: setUpdateTaxToServer } = useCreate({
        refreshUrl: 'getaxList',
        url: 'updatetax',
        onSuccess: () => {
            setIsUpdatedSuccess(true)
            toast.success("Tax Updated Successfully",{
                position:"top-right",
                duration:3000
            })
        },
        onError: () => { }
    })

    const { setDataToServer: setDeleteTaxToServer } = useCreate({
        refreshUrl: 'getaxList',
        url: 'deletetax',
        onSuccess: () => {
            setIsDeletedSuccess(true)
            toast.success("Tax Deleted Successfully",{
                position:"top-right",
                duration:3000
            })
        },
        onError: () => { }
    })
    

    const handleSubmitForm = (e, idx) => {
        e.preventDefault()
        // check if ID exists then call edit service else call create service
        const temp = taxData[idx]
        if (temp?.tax_id) {
            setUpdateTaxToServer.mutate(temp)
        } else {
            console.log("Create")
            setCreateTaxToServer.mutate(temp)
        }
    }


    return (
        <>
            <Layout>
                <div className='category'>
                    <div className='category__head headingBorder__b'>
                        <h2 className='titleHeading'>Taxes</h2>
                    </div>
                    <div className='category_table py-5 space-y-5'>
                        <div className='flex-1'>
                            <input type="text" onChange={handleSearchVal} value={paramsObject.searchQuery} name = 'searchQuery' placeholder='Search Taxes....' className='input' />
                        </div>
                        <div className='grid grid-cols-4 gap-5'>
                            <CreateTax onAddField={handleAddTaxField} />
                            {taxData.map((form, idx) => {
                                return <TaxForm
                                    key={idx}
                                    data={form}
                                    onChange={(e) => handleChangeInput(e, idx)}
                                    onDelete={() => handleDeleteTaxField(idx)}
                                    onSubmit={(e) => handleSubmitForm(e, idx)}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </Layout>
            <SuccessAlert visible={isDeletedSuccess} onClose={() => setIsDeletedSuccess(false)} text={'Tax Item Deleted Succesfully'} />
            <SuccessAlert visible={isCreatedSuccess} onClose={() => setIsCreatedSuccess(false)} text={'Tax Item Create Succesfully'} />
            <SuccessAlert visible={isUpdatedSuccess} onClose={() => setIsUpdatedSuccess(false)} text={'Tax Item Updated Succesfully'} />
        </>
    )
}


const CreateTax = ({ onAddField }) => {
    return <button onClick={onAddField} className='border bg-white hover:border-solid hover:shadow-lg border-gray-600 cursor-pointer rounded-md border-dashed flex flex-col items-center justify-center select-none h-[15.7rem]'>
        <MdAdd className='w-16 h-16 text-gray-600' />
        <span className='text-gray-600'>Add Tax</span>
    </button>
}


const TaxForm = ({ data, onDelete, onChange, onSubmit, isSaving, isDeleting }) => {
    return (
        <>
        <div className='border border-gray-600 p-4 rounded bg-white'>
        <form className='space-y-4' onSubmit={onSubmit}>
            <div>
                <label htmlFor="Tax name" className='label'>Tax name <small className="text-red-700">*</small></label>
                <input required onChange={onChange} value={data.name} type="text" placeholder='Tax Name' name='name' className='input' />
            </div>
            <div>
                <label htmlFor="Tax value" className='label'>Tax value <small className="text-red-700">*</small></label>
                <input required onChange={onChange} value={data.value} type="number" placeholder='Tax value' name='value' className='input' />
            </div>
            <div className=' space-x-4 flex'>
                <Button type='submit' variant="contained" className='primaryBtn-contained w-full'>
                    Save
                    {isSaving && <Spinner className={' w-8 h-8 mx-0'} />}
                </Button>
                <Button onClick={onDelete} type='button' color="error" variant="outlined" className='primaryBtn-contained w-full font-semibold'>
                    Delete
                    {isDeleting && <Spinner className={' w-8 h-8 mx-0'} />}
                </Button>
            </div>
        </form>
    </div>
        </>
    )
}

export default Taxes