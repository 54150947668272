import { Button, Dialog, IconButton, Autocomplete, TextField, createFilterOptions } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FileUploader } from "react-drag-drop-files";
import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addCategory } from "../../apis/category";
import Spinner from '../../components/spinner';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Input from "../../components/elements/Input/input";
import { AiOutlinePlus } from 'react-icons/ai'
import SwitchToggle from '../../components/SwitchToggle/SwitchToggle'
import { toast } from "react-hot-toast";


const fileTypes = ["JPG", "PNG", "JPEG"];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddCategory({ open, onClose, categories }) {
    const categoryOptions = categories?.map((op) => {
        return {
            label: op?.name
        }
    })
    const queryClient = useQueryClient()
    const initialState = {

        thumbnail: {
            image_id: '',
            image_alt: '',
        },
        name: "",
        status: false,
        include_menu: false,
        seo: {
            'meta_title': '',
            'meta_keywords': '',
            'meta_description': ''
        },
        parent_category: '',
        parent_id: '',
        slug: '',
        has_lense: ''

    }
    const [categoryData, setCategoryData] = useState(initialState)
    const [isError, setIsError] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const handleChange = (file, name, previewName) => {
        let data = {
            ...categoryData,
            [name]: file,
            [previewName]: URL.createObjectURL(file)
        }
        setCategoryData(data)
    };
    const handleInputData = (e) => {
        let value = e.target.value
        let data = {
            ...categoryData,
            [e.target.name]: value,
        }
        setCategoryData(data)
    }

    const handleSeoForm = (e) => {
        let value = e.target.value
        let tempName = e.target.attributes['data-name'].value
        let data = {
            ...categoryData,
            seo: {
                ...categoryData?.seo,
                [tempName]: value
            }
        }
        setCategoryData(data)
    }

    const handleCheckedChange = (e) => {
        let checked = e.target.checked
        let data = {
            ...categoryData,
            [e.target.name]: checked
        }
        setCategoryData(data)
    }

    const handlereupload = (name, previewName) => {
        let data = {
            ...categoryData,
            [name]: null,
            [previewName]: null
        }
        setCategoryData(data)
    }

    const handleCloseAndReset = () => {
        onClose()
        setIsSuccess(false)
        setIsError(false)
        setCategoryData(initialState)
    }
    const setCategoryDataToServer = useMutation((data) => addCategory(data), {
        onSuccess: (data) => {
            if (data.status === 200) {
                queryClient.invalidateQueries('categoryList')
                setIsSuccess(true)
                setTimeout(() => {
                    handleCloseAndReset()
                }, 2000)
                toast.success("Category Added Successfully", {
                    position: "top-right",
                    duration: 3000
                })
            }
        },
        onError: () => {
            setIsError(true)
            toast.error("Oops! Something Went Wrong.", {
                position: "top-right",
                duration: 3000
            })

        }
    })
    const handleAddCategory = (e) => {
        e.preventDefault()
        let newData ={
            ...categoryData,
            seo:JSON.stringify(categoryData.seo)
        }
        setCategoryDataToServer.mutate(newData)
    }

    const closeErrorMessage = () => {
        setIsError(false)
    }

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option?.label || ""
    });

    useEffect(() => {
        isError && setTimeout(() => setIsError(false), [4000])
    }, [isError])
    return (
        <>
            <Dialog fullScreen maxWidth={'md'} open={open} onClose={handleCloseAndReset} TransitionComponent={Transition}>
                <div className='container_xxl'>
                    <div className='flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white'>
                        <div>
                            <IconButton onClick={handleCloseAndReset}>
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                        <div>
                            <h1 className='heading'>Add Category</h1>
                        </div>
                    </div>
                    <div className=" flex mt-5 ">
                        <div className="w-full px-20">
                            <form onSubmit={(e) => handleAddCategory(e)}>
                                <div className="flex justify-end">
                                    <Button variant="contained" type="submit">Save</Button>
                                </div>
                                <div className="border-b-2 border-black mt-5 pb-2">
                                    <span className="text-2xl">Currently Active</span>
                                </div>
                                <div>
                                    <div className="pt-6 pb-6">
                                        <div className="py-5 grid grid-cols-2">
                                            <div>
                                                <SwitchToggle value={categoryData.status} label="Enable Category" onChange={(e) => handleCheckedChange(e)} name="status" />
                                            </div>
                                            <div>
                                                <SwitchToggle value={categoryData.include_menu} label="Include Menu" onChange={(e) => handleCheckedChange(e)} name="include_menu" />
                                            </div>
                                        </div>
                                        <div className="mt-5">
                                            <Input
                                                label="Category Name"
                                                placeholder="E.g. Lens"
                                                className="input"
                                                name="name"
                                                onChange={handleInputData}
                                                value={categoryData?.name}
                                                required={true}

                                            />
                                        </div>
                                        <input type="checkbox" name="has_lense" checked={categoryData.has_lense} onChange={handleCheckedChange} />
                                        <label className="label">Does This Category Has Lens ? <small className="text-red-700">*</small></label>
                                    </div>
                                </div>
                                <div className="border-b-2 border-black mt-5 pb-2 ">
                                    <span className="text-2xl ">Content</span>
                                </div>
                                <div className="py-5">
                                    <FileUploader onChange={(file) => handleChange(file, 'image', 'image_preview')} name="image" types={fileTypes} classes="file-upload" />
                                </div>
                                <div className="border-b-2 border-black mt-5 pb-2 ">
                                    <span className="text-2xl ">Search Engine Optimization</span>
                                </div>
                                <div className="mt-2">
                                    <div className="pt-6 pb-6 space-y-9">
                                    <div className="flex">
                                            <div className="w-1/3">
                                                <label className="label">Meta Title</label>
                                            </div>
                                            <div className="w-1/2">
                                                <input type="text" name="seo" data-name="meta_title" onChange={handleSeoForm} placeholder="Meta Title" required value={categoryData?.seo?.meta_title} className='input' />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-1/3">
                                                <label className="label">Meta Keywords</label>
                                            </div>
                                            <div className="w-1/2">
                                                <input type="text" name="seo" data-name="meta_keywords" onChange={handleSeoForm} placeholder="Meta Keywords" required value={categoryData?.seo?.meta_keywords} className='input' />
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="w-1/3">
                                                <label className="label">Meta Description</label>
                                            </div>
                                            <div className="w-1/2">
                                                <input type="text" name="seo" data-name="meta_description" onChange={handleSeoForm} placeholder="Meta Description" required value={categoryData?.seo?.meta_description} className='input' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AddCategory