import './footer.css'

function Footer() {
  return (
    <div className='bg-black text-white text-center py-2'>
      <small>IconMama Dashboard</small>
    </div>
  )
}

export default Footer