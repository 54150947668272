import { Button, Modal } from '@mui/material'
import React, { useRef, useState } from 'react'
import { GrAdd } from 'react-icons/gr'
import useConfigImageUpload from '../../../../hooks/useConfigImageUpload'

const SummaryImage = ({ products, setProducts }) => {
  const [type, setType] = useState('single')
  const [selectAllImages, setSelectAllImages] = useState({
    images_of_product: [],
    images_of_product_preview: []
  })
  const handleChange = (e) => {
    setType(e.target.value)
  }

  const productImageUpload = useRef(null)

  const { setDataToServer: uploadImage } = useConfigImageUpload({
    refreshUrl: '',
    url: "configUploadImage",
    onSuccess: (data) => {
      setProducts({
        mediaId:data?.data?.mediaId,
        path:data?.data?.path
      })
    }
  })

  const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false)
  const handleOpenUploadModal = (e, idx) => {
    setOpenUploadImagesModal(true)
  }
  const handleCloseUploadImageModal = () => {
    setOpenUploadImagesModal(false)
  }
  const handleClickOpenFileDialog = () => {
    productImageUpload.current.click()
  }
  const handleUploadImages = (e) => {
    uploadImage.mutate({
      product_image: e.target.files[0]
    }, {
      onSuccess: ({ data }) => {
        const mediaId = data.mediaId
        let previewImag = []
        for (let i = 0; i < e.target.files.length; i++) {
          previewImag.push(data?.path)
        }
        setSelectAllImages({
          images_of_product: [...selectAllImages.images_of_product, mediaId],
          images_of_product_preview: [...selectAllImages.images_of_product_preview, previewImag]
        })

        let temp = [...products]
        temp.forEach((el, idx) => {
          temp[idx].images_of_product = [...temp[idx].images_of_product, mediaId]
          temp[idx].images_of_product_preview = [...temp[idx].images_of_product_preview, ...previewImag]
        })
        setProducts(temp)
      }
    })

  }

  const handleRemoveSelectedProductImage = (e, id) => {
    e.stopPropagation();

    let selectAllTemp = structuredClone(selectAllImages)
    selectAllTemp.images_of_product_preview.splice(id, 1)
    selectAllTemp.images_of_product.splice(id, 1)
    setSelectAllImages(selectAllTemp)


    let temp = [...products]
    temp.forEach((element, idx) => {
      temp[idx].images_of_product_preview.splice(id, 1)
      temp[idx].images_of_product.splice(id, 1)
    });
    setProducts(temp)
  }

  // console.log({ products: products })

  return (
    <>
      <div className=' space-y-4' onChange={handleChange}>
        <div className='flex'>
          <label className='flex items-center space-x-2 hover:cursor-pointer mr-6'>
            <input type="radio" value={'single'} name='image' checked={type === 'single'} />
            <p>Apply single set of images to all SKUs</p>
          </label>
          {type === 'single' &&
            <Button onClick={(e) => handleOpenUploadModal(e)} variant="contained" color='success' className='primaryBtn-contained'>Add Images</Button>}
        </div>
        <div>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input type="radio" value={'unique'} name='image' checked={type === 'unique'} />
            <p>Apply unique images by attribute to each SKU</p>
          </label>
        </div>
        {/* <div>
          <label className='flex items-center space-x-2 hover:cursor-pointer'>
            <input type="radio" value={'skip'} name='image' checked={type === 'skip'} />
            <p>Skip image uploading at this time</p>
          </label>
        </div> */}
      </div>
      <Modal open={openUploadImagesModal} onClose={handleCloseUploadImageModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <div className=' w-full absolute top-4 left-1/2 -translate-x-1/2'>
          <div className=' max-w-5xl mx-auto bg-white'>
            <div className=' divide-y'>
              <div className="head-modal">
                <div className='flex items-center space-x-4 px-6'>
                  <div className="w-7 h-7 cursor-pointer" onClick={handleCloseUploadImageModal}>
                    <svg className='w-full h-full' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                  </div>
                  <h2 className='heading text-3xl'>Upload Product Images</h2>
                </div>
              </div>
              <div className="body-modal py-4 px-6">
                <div className='max-h-[20rem] min-h-[20rem] overflow-auto  relative cursor-pointer'>
                  <div className='imagePreview__container relative z-10'>
                    <div className="grid grid-cols-4 gap-4 ">
                      {selectAllImages?.images_of_product_preview.map((img, idx) => {
                        return <div className=' w-full h-48 overflow-hidden border p-2 relative cursor-default' key={idx}>
                          <div className='w-6 h-6 cursor-pointer float-right' onClick={(e) => handleRemoveSelectedProductImage(e, idx)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                          <img src={img} alt="img" className='w-full h-full object-contain' />
                        </div>
                      })}
                      <div onClick={handleClickOpenFileDialog} className='w-full h-48 cursor-pointer select-none border-2 rounded-md border-dashed border-gray-400 flex flex-col items-center justify-center hover:shadow-lg'>
                        <GrAdd className=' w-16 h-16 text-gray-400' />
                        <small>Add Product Image</small>
                      </div>
                    </div>
                  </div>
                  {selectAllImages.images_of_product_preview.length <= 0 && <span className='text-gray-400 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>Click here to Upload</span>}
                </div>
                <input type="file" className='hidden' ref={productImageUpload} onChange={handleUploadImages} multiple accept="image/jpeg, image/png, image/jpg" />
              </div>
              <div className="foot-modal py-4 text-center">
                <Button variant="contained" className='primaryBtn-contained px-10' onClick={handleCloseUploadImageModal}>Save Images</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SummaryImage