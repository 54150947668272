import Layout from "../../components/layout";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "@tanstack/react-query";
import { getOrdersList } from "../../apis/orders";
import Spinner from "../../components/spinner";
import Errors from "../errors";
import Moment from "react-moment";
import { useTableSearch } from "../../hooks/useTableSearch";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Pagination from "../../components/elements/Pagination/Pagination";
import { createSearchParams, useSearchParams } from "react-router-dom";
import useReadUpdated from "../../hooks/updated/useRead";
import useCreate from "../../hooks/useCreate";
import spinner from '../../assets/images/common/spinner.gif'
import Slide from '@mui/material/Slide'
import OrderDetails from "./OrderDetails";

function Orders() {
  const [searchParams, setSearchParams] = useSearchParams();
    const statusColors = {
    0: "bg-red-100 text-red-600",
    1: "bg-green-100 text-green-600",
    2: "bg-yellow-100 text-yellow-600",
    3: "bg-red-100 text-red-600",
  };
  const initialState = {
    limit: 10,
    pageNo: searchParams.get("pageNo") || 0,
    searchQuery: "",
    paymentModeFilter:1
  };



  const [orderState, setOrderState] = useState(initialState);
  const [dataCount, setDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(parseInt(initialState.pageNo));
  const [filterStatus, setFilterStatus] = useState(initialState.paymentModeFilter);
  const [openOrderModal,setOpenOrderModal] = useState(false)
  const [orderId,setOrderId] = useState(0)

  const handleGetOrdersList = useQuery(
    ["ordersList", orderState],
    () => getOrdersList(orderState),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (response) => {
        const { total_count } = response.data;
        setDataCount(Math.ceil(total_count / orderState.limit));
      },
    }
  );

  const {list:statusList} = useReadUpdated({
    method:"GET",
    url:"paymentStatusList",
  })

  const { setDataToServer } = useCreate({
    url: "updatepaymentstatus",
    onSuccess: () => {
      handleGetOrdersList.refetch()
    }
  });

  const [searchVal, setSearchVal] = useState("");
  const { filteredData } = useTableSearch({
    searchVal,
    retrieve: handleGetOrdersList?.data?.data.data,
  });

  const handleSearchVal = (e) => {
    let value = e.target.value
    let data = {
        ...orderState,
        searchQuery:searchVal,
        pageNo: 0,
        [e.target.name]: value
    }
    setOrderState(data)
    setCurrentPage(0)
}


  const handleChangeStatus = (status_id, order_id) => {
    const newStatus = { order_id, status_id };
    setDataToServer.mutate(newStatus);
    
  };

  const handleFilterStatus = (e) => {
    let data = {
      ...orderState,
      paymentModeFilter: e.target.value,
    };
    setFilterStatus(e.target.value);
    setOrderState(data);
  };

  const handleOpenOrderModal = (id) => {
    setOrderId(id)
    setOpenOrderModal(true)
  }

  const handleCloseOrderModal = () =>{
    setOpenOrderModal(false)
    
  }



  const handlePageClick = (val) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let pageNo = {
      ...orderState,
      pageNo: val.selected,
    };
    setOrderState(pageNo);
    setCurrentPage(val.selected);
    setSearchParams(
      createSearchParams({
        pageNo: val.selected,
      })
    );
  };

 

  return (
    <>
      <Layout>
        {setDataToServer.isLoading && <div className="fixed w-full h-full  z-50 top-0 left-0 bg-[#ffffff7d]"> <img src={spinner} alt="" className="w-[10rem] h-[10rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /></div>}
        <div className="category">
          <div className="category__head headingBorder__b">
            <h2 className="titleHeading">Orders</h2>
          </div>
          <div className="category_table py-5 space-y-5">
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                <input
                  onChange={handleSearchVal}
                  value={orderState.searchQuery}
                  name="searchQuery"
                  type="text"
                  placeholder="Search Order By Customer Number...."
                  className="input"
                />
              </div>
            </div>
            <div className="w-full flex justify-end items-center px-10 space-x-5">
              <h1>Filter: </h1>
              <div>
                <select
                  value={filterStatus}
                  onChange={(e) => handleFilterStatus(e)}
                  className={
                    `px-4 py-1 rounded capitalize text-sm bg-red-100 text-red-600 ` +
                    statusColors[filterStatus]
                  }
                >
                  {
                    statusList?.data?.data?.map((status)=>
                    {
                        const {status_id,payment_status} = status
                          return(
                                  <>
                                  <option value={status_id} className={statusColors[status_id]}>{payment_status}</option>
                                  </>
                                )
                    })
                  }
                </select>
              </div>
            </div>
            {handleGetOrdersList?.data?.data.data?.length === 0 ? (
              <h1 className="text-center font-semibold text-2xl">
                No Data Found
              </h1>
            ) : (
              <div>
                {handleGetOrdersList.isLoading ? (
                  <Spinner />
                ) : handleGetOrdersList.status === "error" ? (
                  <Errors errorObject={handleGetOrdersList.error} inline />
                ) : (
                  <>
                    <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
                      <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                        <thead>
                          <tr className="text-left">
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                              Sr.no
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Customer Phone
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Order Date
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Total Sum
                            </th>
                            {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Reference No
                            </th> */}
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              Order State
                            </th>
                            <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                              View
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {handleGetOrdersList?.data?.data.data?.map((orderData, idx) => {
                            const {
                              order_id,
                              total_sum,
                              order_token,
                              order_state,
                              ordered_at,
                              status_id,
                              customer_id,
                              payment_status,
                            } = orderData;
                            return (
                              <tr key={idx}>
                                <td className="border-dashed border-t border-gray-200 userId">
                                  <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                                    {/* {(orderState.limit * (orderState.pageNo + 1)) - (orderState.limit - (idx + 1))}
                                     */}
                                    {orderState.limit * orderState.pageNo + idx + 1}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center firstName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    {customer_id}
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center lastName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    <Moment date={ordered_at} unix   format="llll" />
                                  </span>
                                </td>
                                <td className="border-dashed border-t border-gray-200 text-center lastName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    &#8377; {total_sum}
                                  </span>
                                </td>
                                {/* <td className="border-dashed border-t border-gray-200 text-center lastName">
                                  <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-sm">
                                    {order_token}
                                  </span>
                                </td> */}
                            
                               
                                <td className="border-dashed border-t border-gray-200 emailAddress">
                                <div className='flex items-center justify-center'>
                                  <select value={status_id} onChange={(e) => handleChangeStatus(e.target.value, order_id, status_id)} className={`px-4 py-1 rounded capitalize text-sm ` + statusColors[status_id]}>
                                    {
                                      statusList?.data?.data?.map((status) => {
                                        const { status_id, payment_status } = status
                                        return (
                                          <>
                                            <option value={status_id} className={statusColors[status_id]}>{payment_status}</option>
                                          </>
                                        )
                                      })
                                    }
                                  </select>
                                </div>
                                
                                </td>
                                <td className="border-dashed border-t border-gray-200 phoneNumber">
                                  <div className="flex items-center space-x-5 justify-center">
                                    <div
                                      onClick={()=> handleOpenOrderModal(order_id)}
                                    >
                                      <IconButton>
                                        <RemoveRedEyeIcon />
                                      </IconButton>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className=" my-7 flex justify-center items-center">
                      <Pagination
                        currentPage={currentPage}
                        pageCount={dataCount}
                        onPageChange={handlePageClick}
                        limit={initialState.limit}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Layout>
      {openOrderModal && <OrderDetails open = {openOrderModal} onClose = {handleCloseOrderModal} id = {orderId}/>}
      
    </>
  );
}

export default Orders;
